#dump {
	display: none
}

#floatbox {
	position: fixed;
	left: 25%;
	right: 25%;
	top: 20%;
	z-index: 998;
}

#floatbox_close {
	position: absolute;
	background: url(../images/close.png) 0 0 no-repeat;
	display: block;
	width: 24px;
	height: 24px;
	right: -5px;
	top: -5px;
	z-index: 999;
	:hover { /*background-position:0 -24px*/}
	:active { /*background-position:0 -48px*/}
}

#floatbox_frame {
	background: #fff;
	padding: 20px;
	border: 2px solid #ddd
}

#floatbox_content {
	min-width: 400px;
	max-width: 80vw;
	min-height: 100px;
	max-height: 50vh;
	position: relative;
	overflow: auto;
	text-align: center
}

@media (max-width: 600px) {
    #floatbox {
        left: 5%;
        right: 5%;
    }
	#floatbox_content {
		min-width: 100%;
	}
}