button.fa.fa-shopping-cart {
    border: 0;
    padding: 5px 20px;
    font-size: 24px;
    float: right;
    margin-right: 10px;
    &:hover {
        background: $delta;
        color: $beta;
    }
}

.btn-success {
    color: #fff;
    background-color: #CA242A;
    border-color: #CA242A;
}

.btn-primary {
    color: #fff;
    background-color: #CA242A;
    border-color: #CA242A;
    &:hover {
        background: #fff;
        color: #CA242A;
    }
    @include tablet {
        display: block;
        margin: 20px auto;
    }
}