// color

$white: #fff;
$black: #000;
$ivory: #fffff0;
$gray: #E4E4E4;
$gray1: #C5CFD2;
$gray2: #D8D8D8;
$red: #CA242A;
$blue: #113063;
$green: #23a600;
$dotted: #767676;

$alpha: #000;
$beta: #263664;
$gama: #767676;
$delta: #70c6f0;
$epsilon: #e9ecef;
$zeta: tomato;

$font: 'Roboto';
$fontr1: sans-serif;
$font2: 'Roboto';
$font3: 'Roboto';
$fontr2: sans-serif;

$big: 1200px;
$mid: 992px;
$small: 544px;
$xsmall: 450px;
