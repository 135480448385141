.category {
  background: $epsilon;

  .item {
    h2 {
      font-weight: normal;
      // margin: 40px 0 30px;
      border-bottom: 1px solid;
    }

    figure {
    }

    p {
      font-size: 13px;
    }

    .detail {
      float: right;
    }

    img.thumbnail {
      /*   border-radius: 20px;*/
    }
  }
}

#box-category {
  margin-left: 0;

  ul {
    margin-left: 0;
    list-style: none;
    padding-left: 0;

    span {
      margin-top: -20px;
      width: 100%;

      .fa {
        float: right;
      }
    }

    ul {
      padding: 20px 0 0 20px;
    }
  }
}

// .inner-right {
//     #box-category {
//         ul {
//             li {
//                 background: #eee;
//                 width: 100%;
//                 padding: 16px;
//                 cursor: pointer;
//             }
//         } 
//     }
// }

// ul.cat-list{
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     li {
//         list-style: none;
//         margin: 1px 0;
//         /*text-transform: uppercase;*/
//         /*list-style-type: upper-roman;*/
//         list-style-position: inside;
//         &.drop-menu {
//             margin-bottom: 10px;
//             ul {
//                 padding-left: 15px;
//                 text-indent: -10px;
//             }
//         }
//         .sub {
//             font-size: 16px;
//             line-height: 20px;            
//             margin: 8px 5px;            
//         }
//     }
// }

// article.cat-nominated{
//     h1 {
//         font-weight: normal;
//         text-decoration: none;
//         text-transform: none;
//     }
// }

// section.cat-items {
//     background: $epsilon;
//     padding: 10px;
//     .item {
//         margin: 10px 0;
//     }
// }

// .item-col-left {
//     background: #fff;
//     padding: 5px 10px;
//     .item-img-holder {
//         width: 50px;
//         height: 50px;
//         text-align: center;
//         white-space: nowrap;
//         float: left;
//         .helper {
//             display: inline-block;
//             height: 100%;
//             vertical-align: middle;
//         }
//         img {
//             vertical-align: middle;
//             float: none;
//             margin: 0;
//             max-width: 50px;
//             max-height: 50px;            
//         }
//     }
//     .item-title-holder{
//         min-height: 50px;
//         vertical-align: middle;
//         color: $alpha;
//         /*line-height: 50px;*/
//         margin: 0 10px;
//         float: left;
//         max-width: 80%;
//         a {
//             color: $alpha;
//         }
//     }
// }
// .item-col-right {
//     padding: 10px 20px;
// }

// .cat-nominated-note{
//     background-color: #EBF6FF;
//     padding: 20px;
// }

// .item-title-0 {display: none}
// .cat-2, .cat-3, .cat-9, .cat-10, .cat-25, .cat-26 {
//     .item-title-0, .item-title-2, .item-title-3{
//         display: none;
//     }
// }
// .cat-28 {
//     .item-title-0, .item-title-1, .item-title-3{
//         display: none;
//     }
// }
// .cat-30, .cat-31{
//     .item-title-0, .item-title-1, .item-title-2{
//         display: none;
//     }
// }

// #category-glasanje,
// #category-novosti{
//     .box-subcat {
//         display: none;    
//     }
// }
// .box-subcat{
// 	background: $gray;
// 	height: auto;
// 	ul {
//         padding: 0;
//         li {
//             list-style: none;
//             padding: 0;
//             font-size: 14px;
//             a {
//                 color: $alpha;
//             }
//         }
//     }
// }
#category-proizvodi .container.inner section {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}

//shop-full//
.ad_short_product {
  //flex: 1 1 calc(100% / 3);
 //max-width: calc(100% / 3);

  @include lphone {
    //flex: 1 1 100%;
    //max-width: 100%;
    //margin-bottom: 20px !important;
  }

  .box-ad-short {
    background-color: $white;

    &:hover {
      -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      -webkit-transform: translateY(-4px);
      -moz-transform: translateY(-4px);
      -webkit-transition: width 2s; /* Safari */
      transition: width 2s;
      transition: all 0.45s ease;
    }

    .ad-short-image {
      margin: 0 auto !important;

      .flex {
        width: 100%;
        text-align: center;
      }

      img.thumbnail {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        float: none;
        padding: 10px;
      }
    }

    .ad-short {
      margin-left: 0px;
      margin-right: 0px;

      .flex {
      }

      &-image {
        a {
          overflow: hidden;
          display: block;
          max-height: 308px;
          width: 100%;
        }

        img {
          margin: 0 auto 10px;
          /*border-bottom: 1px solid $gray1;*/
          max-width: 100%;
          max-height: 300px;

          &:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            opacity: 0.3;
            filter: alpha(opacity=30);
            -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
            transition: all 0.45s ease;
          }
        }
      }

      .title {
        display: block;
        color: $beta;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        zoom: 1;
        height: 75px;
      }

      .stock {
        color: $alpha;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        display: inline-block;
        padding: 5px 10px;
        float: left;
        margin-bottom: 0px;
        zoom: 1;
      }

      .price {
        display: block;
        margin-bottom: 10px;
        min-height: 58px;
        .old-price {
          color: #c3c3c3;
          text-decoration: line-through;
          white-space: pre;
        }
      }
      .code {
        letter-spacing: -0.09em;
        padding: 0;
        margin-bottom: 10px;
      }
      .color {
        padding: 0;
        margin-bottom: 10px;
      }
      .cat {
        letter-spacing: -0.05em;
        padding: 0;
        margin-bottom: 10px;
        min-height: 72px;
      }
      .brand {
        min-height: 63px;
        padding: 0;
        margin-bottom: 10px;
        .logo-img {
          width: calc(100% - 20px);
        }
        .logo-brend {
          width: 30px;
          margin: auto;
          position: absolute;
          right: 0px;
          margin-top: 5px;
      }
      }

      .product-cart {
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        height: 50px;
        text-align: center;
        padding-left: 25%;
        width: 100%;

        a {
          color: #000;

          &:hover {
            background-color: $white;
            color: $blue;
          }
        }

        .add-wishlist {
          width: 50px;
          height: 49px;
          padding: 0;
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
          position: relative;
          top: 0;
          right: auto;
          float: left;
          bottom: auto;
          line-height: 50px;
        }

        .add-compare {
          width: 50px;
          height: 50px;
          padding: 0;
          border-right: 1px solid #e5e5e5;
          position: relative;
          top: 0;
          right: auto;
          bottom: auto;
          float: left;
          line-height: 50px;
        }

        .add-cart {
          width: 50px;
          height: 50px;
          padding: 0;
          border-right: 1px solid #e5e5e5;
          position: relative;
          top: 0;
          right: auto;
          bottom: auto;
          float: left;
          line-height: 50px;
        }
      }

      .info-ad {
        margin: 10px 0;
        padding: 0;
        min-height: 40px;
        line-height: 18px;
        .col-6 .col-7 {
          @include lphone {
            padding: 0 5px;
            margin-bottom: 10px;
          }
        }

        .title {
          display: block;
          width: 100%;
          font-size: 16px;
          line-height: 18px;
          font-weight: 700;
          zoom: 1;
          height: 73px;
          overflow: hidden;
          margin-bottom: 10px;
          padding: 0;
        }

        .price {

          .old-price {
            color: #c3c3c3;
            text-decoration: line-through;
            white-space: pre;
            font-weight: 600;
          }
        }
      }

    }

    .order {
      padding: 0 0 0 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .input-group {
        flex: 1 1 90px;
        max-width: 70px;
        margin-right: 10px;
        height: 40px;
        border: 2px solid $gray1;
        background: $white;

        .form-control {
          background: none;
          border: none;
          display: flex;
          align-items: center;
          box-shadow: none;
          padding: 0px;
          text-align: center;
          letter-spacing: 1px;
        }

        .input-group-btn-vertical {
          line-height: 100%;

          .btn {
            height: 20px;
            width: 20px;
            line-height: 20px;
            padding: 0;
            margin: 0;
            border: none;
            background: none;
            cursor: pointer;
            text-align: center;
            display: block;
            box-shadow: none;

            &:hover {
              text-decoration: none;
            }
          }

          .btn i {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            text-align: center;
          }

          .btn.bottom {
            margin: -5px 0 0 0;
          }
        }
      }
    }

  }

}
.cat-mobile {
  display: none;
  @include lphone {
    display: block;
    text-align: center;
    margin: 40px 0;
    a {
      padding: 15px 40px;
      border: 1px solid #CA242A;
      background-color: #fff;
      font-weight: normal;
      color: #5E5E5E;
    }
  }
}
.category-filter {
  position: relative;
  height: 172px;
  @include lphone {
    display: none;
  }
  .categories {
    li {
      margin-right: 3px;
      text-align: center;
      &:first-child, &:last-child {
        margin-right: 0;
      }
      &.active {
        color: $red;
      }
      &.category-13 {
        a{
          color: #04c836;
        }
    }
    a{
      display: inline-block;
      width: 100%;
    }
    }
  }
  ul {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 10px 20px;
      // flex: 1 1 33.33%;
      flex: 1 1 50%;
      // max-width: calc(100% / 3);
      max-width: calc(100% / 2);
      background: #fff;
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      align-items: center;
      transition: all 0.5s ease-in-out;
      color: #b6b4b5;
      text-transform: uppercase;
      font-weight: bold;
      position: relative;
      cursor: pointer;
      @include tablet {
        font-size: 18px;
        padding: 0 10px;
      }
      h3 {
        font-size: 16px;
        margin: auto;
      }
      a{
        color: #CA242A;
      }

      &:last-child:before, &.active:before {
        display: none;

      }

      img {
        max-width: 200px;
        max-height: 150px;
        height: auto;
        flex: 0 auto;
        filter: grayscale(100%);
        transition: all 0.5s ease-in-out;
        @include tablet {
          max-width: 130px;
        }
      }

      &.active {
        background: $epsilon;
        cursor: pointer;
        img {
          filter: grayscale(0);
        }
      }
    }
  }

  .subcategory {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-in-out;
    position: absolute;
    top: 52px;
    left: 0;
    right: 0;
    background: $white;

    li {
      flex: 1 1;
      // flex: 1 auto;
      //max-width: 12.5%;//
      padding: 20px 10px;
      height: auto;
      text-align: center;
      background: $white;
      position: relative;
      @include tablet-only {
        padding: 0;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 20px;
        bottom: 20px;
        z-index: 1;
        right: -0.5px;
        width: 1px;
        background: $epsilon;
        transition: all 0.5s ease-in-out;
      }

      &:last-child:before {
        display: none;
      }

      a {
        display: block;
        width: 100%;
        text-align: center;
      }

      span {
        width: 100%;
        text-align: center;
      }

      span.title {
        font-size: 13px;
        line-height: 15px;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        align-items: center;
        color: #b6b4b5;
        margin-bottom: 10px;
        text-transform: uppercase;
        @include tablet {
          font-size: 12px;
          line-height: 14px;
        }
        @include desktop-only {
          line-height: 18px;
          font-size: 14px;
        }
      }

      img {
        display: block;
        clear: both;
        margin: 0 auto;
        max-height: 40px;
        opacity: 0.75;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }

      &:hover {
        img {
          opacity: 1;
          transform: scale(1.1);
          transition: all 0.45s ease;
          -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
        }

        span.title {
          color: $red;
        }
      }
      &.active {
        background: #fff;
        .title {
          color: $red;
        }
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 20px;
          bottom: 20px;
          right: -0.5px;
          width: 1px;
          background: #e9ecef;
          transition: all 0.5s ease-in-out;
      }
      }
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.search-products {
  padding: 30px 0 20px;
  position: relative;

  &:after {
    position: absolute;
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    bottom: 20px;
    left: 32%;
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: block;
    font-weight: bold;
    font-size: 24px;
    color: $red;
    @include tablet {
      display: none;
      //top: 28px;
      //left: auto;
      //right: 0px;
    }
    @include lphone {
      display: none;
    }
  }

  .int {
    display: inline-block;
    width: 36%;
    background-color: #fff;
    height: 36px;
    top: -1px;
    position: relative;
    @include lphone {
      width: 100%;
    }
    input {
      width: 100%;
      background: transparent;
      position: relative;
      z-index: 998;
    }
  }

  .filter-box {
    display: inline-block;
    width: 63%;
    @include tablet {
      width: 100%;
      margin-top: 20px;
    }
    select {
      display: inline-block;
      padding: 6.5px 10px;
      position: relative;
      //margin-right: 1%;
      width: 39%;
      font-weight: normal;
      color: #5E5E5E;
      background-color: #fff;
      border: 1px solid #c5c6c8;
      @include lphone {
        width: 100%;
        margin: 20px 0 0;
      }
      &::placeholder {
        text-transform: unset;
        font-size: 15px;
      }
    }
    .btn {
      width: 125px;
      position: absolute;
      display: inline-block;
      right: 0;
      background-color: #8a8889;
      border-color: #fff;
      border-radius: 0;
      padding: 5px 12px;
      @include lphone {
        width: 100%;
        position: relative;
      }
    }
  }

  input {
    display: inline-block;
    width: 35.5%;
    padding: 5px 10px;
    position: relative;
    margin-right: 1%;
    border: 1px solid #c5c6c8;
    @include tablet {
      width: 100%;
    }

    &:focus {
      outline: none;
    }
    &::placeholder {
      text-transform: unset;
      font-size: 15px;
    }
  }
}

.search-boxes-list {
  &.hidden {
    display: none !important;
  }
}

.search-boxes {
  ul {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      flex: 1 1 25%;
      max-width: 25%;
      @include lphone {
        flex: 1 1 33.33%;
        max-width: 33.33%;
      }
    }
  }

  label {
    display: block;
    clear: both;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 15px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: none;
      border: 1px solid $white;
      overflow: hidden;

      &:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        position: absolute;
        display: none;
        height: 22px;
        width: 22px;
        line-height: 22px;
        text-align: center;
      }
    }

    &:hover input ~ .checkmark {
      background-color: $red;
    }

    input:checked ~ .checkmark {
      background-color: $red;
    }

    input:checked ~ .checkmark:after {
      display: block;
      color: $white
    }
  }
}

.recomendations {
  background: $epsilon;
  padding: 20px 0;

  .row {
    margin: 0;

    .col-12 {
      margin: 0;
      padding: 0;
    }
  }

  h3 {
    font-size: 30px;
    margin: 40px 0;
    color: #CA242A;
    font-weight: normal;
    text-transform: uppercase;
  }

  .col-12 .row {
    justify-content: space-between;
  }

  .product {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    align-items: center;
    padding-left: 0;
    border: 1px solid $alpha;
    flex-wrap: nowrap;
    //max-width: 32%;
    background: $white;
    max-height: 200px;
    margin: 0 -10px 10px 0 !important;
    @include lphone {
      max-width: 100%;
      margin-bottom: 20px !important;
    }
    @include desktop-only {
      &:nth-child(3) {
        display: none;
      }
    }

    .badge {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      background: $red;
      color: $white;
      text-align: center;
      height: -webkit-fill-available;
      height: -moz-available;
      flex: 1 1 40px;
      padding-right: 7px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 20px;
      position: absolute;
      @include tablet {
        max-width: 40px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    a {
      flex: 1 1 150px;
      max-width: 150px;
      @include tablet {
        flex: 1 1 100%;
        max-width: calc(100% - 40px);
      }

      img {
        max-width: 100%;
        max-height: 190px;
        @include tablet {
          margin: 0 auto;
          display: block;
        }
      }
    }

    .info {
      flex: 1 1 calc(100% - 190px);
      max-width: calc(100% - 190px);
      display: flex;
      justify-content: flex-start;
      flex-flow: column nowrap;
      padding-left: 20px;
      @include tablet {
        flex: 1 1 100%;
        width: 100%;
        max-width: 100%;
        margin: 20px 0;
      }

      h4 {
        font-weight: bold;
        font-size: 18px;
        color: $red;
        margin: 0;
        a {
          color: $red;
        }
      }

      h5, h6 {
        font-size: 16px;
        margin: 0;
        font-weight: 300;
      }

      h5 {
        color: $red;
        font-weight: normal;

      }

      .code {
        text-transform: uppercase;
      }

      .head-price {
        .old-price {
          color: #c3c3c3;
          text-decoration: line-through;
          white-space: pre;
        }
      }

      p {
        display: block;
        font-size: 14px;
        margin: 0;
        font-weight: bold;

        span {
          display: block;
          color: $red;
          clear: both;
          text-decoration: none;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  a.more {
    margin-top: 20px;
    font-size: 24px;
    font-weight: normal;
    color: $red;
  }
}

.product-category-view {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  justify-content: stretch;

  .ad_short_product {
    position: relative;
    justify-content: stretch;

    .product-cart {
      display: none
    }

    &.ad-sale {
      &:before {
        position: absolute;
        right: 15px;
        top: 10px;
        z-index: 999;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 80px 50px 0;
        border-color: transparent $green transparent transparent;
      }

      &:after {
        position: absolute;
        content: "akcija";
        text-transform: uppercase;
        display: block;
        width: 30px;
        height: 20px;
        text-align: center;
        right: 35px;
        top: 12.5px;
        color: $white;
        z-index: 1000;
        transform: rotate(32deg);
      }
    }

    &.ad-new {
      &:before {
        position: absolute;
        left: 15px;
        top: 10px;
        z-index: 999;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 80px 0 0;
        border-color: $red transparent transparent transparent;
      }

      &:after {
        position: absolute;
        content: "novo";
        text-transform: uppercase;
        display: block;
        width: 30px;
        height: 20px;
        text-align: center;
        left: 15px;
        top: 22px;
        color: $white;
        z-index: 1000;
        transform: rotate(-35deg);
      }
    }

    &.ad-promo {
      &:before {
        position: absolute;
        left: 15px;
        top: 10px;
        z-index: 999;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 80px 0 0;
        border-color: #04c836 transparent transparent transparent;
      }

      &:after {
        position: absolute;
        content: "promo";
        text-transform: uppercase;
        display: block;
        width: 30px;
        height: 20px;
        text-align: center;
        left: 17px;
        top: 25px;
        color: $white;
        z-index: 1000;
        transform: rotate(-35deg);
        font-size: 15px;
      }
    }
  }

  .info-ad {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    color: #5E5E5E;
    font-size: 16px;
    line-height: 25px;
    border-top: 1px solid $alpha;
    padding: 10px 0 !important;
    margin: 0 15px !important;

    .price {
      color: #04c836;
      font-size: 16px;
      line-height: 18px;
      padding: 0;
    }

    .col-md-6 {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      align-items: baseline;
      margin: 0 0 20px;
      padding: 0;

      &.price, &.color, &.brand {
        padding-left: 50px;
        text-align: left !important;
        align-content: flex-start;
        float: right;
      }
      .color {
        padding-left: 50px;
        text-align: left !important;
        align-content: flex-start;
        float: right;
      }

    }

    a {
      color: #5E5E5E;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: -0.05em;

      span {
        font-size: 14px;
        margin: 0;
      }
    }

    span {
      line-height: 20px;
      width: 100%;
      display: block;
      clear: both;
      font-size: 13.5px;
      color: $alpha;
      letter-spacing: normal;
    }
    .color {
      text-align: right;
      span {
      }
    }
  }

}
.list-level .product-category-view .ad_short_product.ad-sale::before {
  border-color: transparent red;
}