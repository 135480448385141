footer {
    color: $white;
    background-color: #58595b;
    border-top: 2px solid $red;
	font-size: 14px;
    line-height: 18px;
    @include lphone {
        text-align: center;
    }
    h3 {
        font-size: 22px;
        padding-bottom: 10px;
    }
    a {
        color: $white;
        &:hover {
            color: $white;
        }
    }
    .footer-logos {
        padding: 30px 0;
        text-align: center;
        span{
            font-weight: bold;
        }
        .top-social {
            margin-bottom: 10px;
           
        }
        img {
            max-width: 150px; /*nikolina*/
            margin-bottom: 30px;
        }
        .top-social {
            ul {
                list-style: none;
                float: left;
                margin: 0;
                padding: 0;
            }
            li {
                float: left;
                padding: 0 10px;
            }
        }
    }
    .footer-menu {
        padding: 30px 0;
        .menu-1 {
            ul {
                margin-bottom: 0;
                @include lphone {
                    padding-left: 0;
                }
                li {
                    list-style: none;
                    padding-bottom: 8px;
                    a {
                        color: $white;
                    }
                }
            }
        }  
    }  

    .info {
        padding: 30px 0;
    }

    .links {
        padding: 30px 0;
        .linkovi {
            ul {
                padding-left: 0;
                padding-top: 30px;
                li {
                    list-style: none;
                    padding-bottom: 8px;
                }
            }
        }
    }
   
    .footer-service {
        padding: 30px 0;
        ul {
            padding-left: 0;
            li {
                color: #263664;
                font-family: Cuprum;
                font-size: 17px;
                line-height: 19px;
                font-weight: bold;
                text-transform: uppercase;
                list-style: none;
                margin: 17px 0;
                padding: 0;
                a{
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }  
    .copyright {
        border-top: 1px solid #5E5E5E;
        text-align: center;
        padding: 10px 0;
        background-color: #D8D8D8;
        color: #5E5E5E;
        a {
            color: #5E5E5E;
        }
    }
}





// .divider {
//     margin: 20px 0 30px 0;
//     border-bottom: 4px dotted $dotted;
// }
