nav.navigation {
  color: #999;
  margin: 24px 0 4px;
  padding: 8px;
  text-align: center;
  .paginations {
    text-align: center;
    .list {
      display: inline-block;
      .page-link.active {
        z-index: 1;
        color: #fff;
    background-color: #da0000;
        border-color: #e1e1e1;
      }
      a {
        display: inline-block;
        vertical-align: middle;
        background: $white;
        border: 1px solid #e1e1e1;
        font-size: 15px;
        text-align: center;
        line-height: 13px;
        color: #333;
        transition: all 0.4s;
        margin: 3px;
        padding: 8px 14px;
        &:hover {
          background: #da0000;
          color: #fff;
        }
        &.prev {
          .fa {
            margin-left: -3px;
          }
        }
        &.next {
          .fa {
            margin-right: -3px;
          }
        }
      }
      .fa {
        font-size: 0.75rem;
      }
    }
  }
}