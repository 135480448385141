.product.mm-page {
  // background: $epsilon;
}

.ad_product {
  background: $white;

  &.ad-sale .detail-img {
    &:before {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 999;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 80px 50px 0;
      border-color: transparent $green transparent transparent;
    }

    &:after {
      position: absolute;
      content: "akcija";
      text-transform: uppercase;
      display: block;
      width: 30px;
      height: 20px;
      text-align: center;
      right: 25px;
      top: 0px;
      color: $white;
      z-index: 1000;
      transform: rotate(32deg);
    }
  }

  &.ad-new .detail-img {
    &:before {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 999;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 50px 80px 0 0;
      border-color: $red transparent transparent transparent;
    }

    &:after {
      position: absolute;
      content: "novo";
      text-transform: uppercase;
      display: block;
      width: 30px;
      height: 20px;
      text-align: center;
      left: 0px;
      top: 12px;
      color: $white;
      z-index: 1000;
      transform: rotate(-35deg);
    }
  }

  &.ad-promo .detail-img {
    &:before {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 999;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 50px 80px 0 0;
      border-color: #04c836 transparent transparent transparent;
    }

    &:after {
      position: absolute;
      content: "promo";
      text-transform: uppercase;
      display: block;
      width: 30px;
      height: 20px;
      text-align: center;
      left: 0px;
      top: 12px;
      color: $white;
      z-index: 1000;
      transform: rotate(-35deg);
    }
  }

  .row.row-img {
    margin: 20px 0;
  }

  .product-img-box {
    padding: 0 !important;

    .detail-img {
      max-width: 100%;
      position: relative;
      border: 1px solid #e9ecef;
      margin: 20px;

      img {
        max-width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .small-img {
      margin: 10px -5px;
      position: relative;

      .carousel {
        overflow: hidden;
        margin: 0 5px;
      }

      .item img {
        max-height: 67px;
        -o-transition: all 0.2s ease-out 0s;
        -ms-transition: all 0.2s ease-out 0s;
        -moz-transition: all 0.2s ease-out 0s;
        -webkit-transition: all 0.2s ease-out 0s;
        transition: all 0.2s ease-out 0s;
        margin: 0px auto;
        display: block;
      }

      .item {
        float: left;
        height: 70px;
        margin: 0 10px;
        border: 1px solid #c5cfd2;
      }

      .item img {
        max-height: 67px;
        -o-transition: all 0.2s ease-out 0s;
        -ms-transition: all 0.2s ease-out 0s;
        -moz-transition: all 0.2s ease-out 0s;
        -webkit-transition: all 0.2s ease-out 0s;
        transition: all 0.2s ease-out 0s;
        margin: 0px auto;
        display: block;
      }
    }
  }

  .product-shop {
    background: $white;
    .piktogram {
      figure {
        display: inline-block;
        float: left;
        img{/*nikolina*/
          margin: 10px;
          max-width: 50px;
        }
        figcaption {
          font-size: 12px;
          text-align: center;
        }
      }
    }
    h1 {
      color: #CA242A;
      position: relative;
      padding-right: 165px;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      height: 70px;
      line-height: normal;
      @include phone {
        display: block;
        padding-right: 0;
      }
      span {
        position: absolute;
        right: 10px;
        top: 10px;
        max-height: 70px;
        display: flex;
        justify-content: center;
        flex-flow: row nowrap;
        align-items: center;
        @include phone {
          top: auto;
        }
        .product-location {
          height: 30px;
          right: 0;
          margin-bottom: 10px;
          position: absolute;
        }
        .logo-brend {
          left: -25px;
          position: relative;
          @include phone {
            left: 0;
          }
      }
        img {
          max-width: 100%;
          max-height: 60px;
        }
      }
    }

    .button {
      margin: 10px 0;
      float: right;

      .add-wishlist {
        display: inline-block;
        font-size: 16px;
        line-height: unset;
        padding: 5px 15px;
        text-align: center;
        height: 39px;
        color: #988d66;
        background: #f2f1ec;
        margin-right: 10px;

        &:hover {
          background: $beta;
          color: $delta;
        }
      }

      .add-compare {
        display: inline-block;
        font-size: 16px;
        line-height: unset;
        padding: 5px 15px;
        text-align: center;
        height: 39px;
        color: #988d66;
        background: #f2f1ec;

        &:hover {
          background: $beta;
          color: $delta;
        }
      }
    }

    .old-price {
      color: #cccccc;
      text-decoration: line-through;
      line-height: 12px;
      font-weight: 400;
    }

    .order select {
    }

    .ad-short {
      margin-left: 0px;
      margin-right: 0px;

      .flex {
      }

      img {
        margin-right: 0px;
        border-bottom: 1px solid $gray1;
        margin-bottom: 10px;
      }

      .title {
        color: $alpha;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        display: inline-block;
        padding: 0 0 5px 10px;
        float: left;
        border-bottom: 1px solid $gray1;
        zoom: 1;
      }

      .stock {
        color: $alpha;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        display: inline-block;
        padding: 5px 10px;
        float: left;
        margin-bottom: 0px;
        zoom: 1;
      }

      .price {
        color: $alpha;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        display: inline-block;
        padding: 5px 10px;
        display: inline-block;
        float: right;
        text-align: right;
        zoom: 1;
      }
    }

    .order {
      padding: 0 0 0 10px;
    }

    .desc {
      padding-top: 20px;
      margin: 0;
      h4 {
        // text-align: end;
      }
    }
  }

  .similar {
    ul.related {
      height: 415px;
      overflow: hidden;

      .item-similar {
        max-height: 415px;

        .box-similar {
          background-color: $white;

          &:hover {
            -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
            -webkit-transform: translateY(-4px);
            -moz-transform: translateY(-4px);
            -webkit-transition: width 2s; /* Safari */
            transition: width 2s;
            transition: all 0.45s ease;
          }
        }

        .detail-img {
          background-color: $white;
          margin-left: 0;
          margin-right: 0;

          img {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            float: none;
            padding: 15px;
            height: 308px;
            background-color: $white;

            &:hover {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
              -moz-transform: scale(1.1);
              -o-transform: scale(1.1);
              opacity: 0.3;
              filter: alpha(opacity=30);
              -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
            }
          }
        }

        .info {
          background: $white;
          float: inherit;
          padding-bottom: 5px;
          margin-left: 0;
          margin-right: 0;

          h3 {
            color: #988d66;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
            display: inline-block;
            padding: 0 0 10px 15px;
            float: left;
          }
        }

        .button {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 15px;
          padding-bottom: 15px;

          .add-wishlist {
            display: inline-block;
            font-size: 16px;
            line-height: 34px;
            width: 67px;
            text-align: center;
            height: 34px;
            color: #988d66;
            background: #f2f1ec;
            padding: 0;
            margin-right: 10px;

            &:hover {
              background: $beta;
              color: $delta;
            }
          }

          .add-compare {
            display: block;
            font-size: 16px;
            line-height: 34px;
            width: 67px;
            text-align: center;
            height: 34px;
            color: #988d66;
            background: #f2f1ec;
            padding: 0;
            margin-left: auto;
            margin-right: auto;

            &:hover {
              background: $beta;
              color: $delta;
            }
          }
        }
      }
    }
  }
}
// article.category.list-level{
//   .product-category-view{
//     .ad_short_product.ad-new{
//       &:before{
//         display: none;
//       }
//     }
//   }
// }

// headline_ad//
.box-product {
  padding: 10px 0;

  .item {
    .img-holder {
      max-height: 310px;

      img {
        margin-right: 0px;
        margin-bottom: 10px;
        //border-bottom: 1px solid $gray1;
        //box-shadow: 1px 1px 5px 1px $gray1;
      }
    }

    .options {
      min-height: 40px;
    }

    .title {
      color: $alpha;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      padding: 0 0 5px 10px;
      zoom: 1;
    }

    .price {
      float: right;
    }

    .form-order {
      float: right;
      margin-bottom: 5px;
    }

    .spinner {
      float: left;
    }

    .add2bag {
      float: left;
    }

    .buttons {
      margin: 0 15px;
      float: left;
      display: none;
    }

    &:hover {
      .buttons {
        display: block;
      }
    }
  }

  .card {
    .card-body {
      //box-shadow: 1px 1px 10px $epsilon;
      border: 1px solid $epsilon;
      height: 100%;

      &:hover {
        box-shadow: 1px 1px 10px $epsilon;
      }
    }
  }

  // .product-img-box{
  //     .detail-img{
  //         max-width: 100%;
  //         img {
  //             max-width: 100%;
  //         }
  //     }
  //     .small-img {
  //         margin: 10px -5px;
  //         position: relative;
  //         .carousel {
  //             overflow: hidden;
  //             margin: 0 5px;
  //         }
  //         .item img {
  //             max-height: 67px;
  //             -o-transition: all 0.2s ease-out 0s;
  //             -ms-transition: all 0.2s ease-out 0s;
  //             -moz-transition: all 0.2s ease-out 0s;
  //             -webkit-transition: all 0.2s ease-out 0s;
  //             transition: all 0.2s ease-out 0s;
  //             margin: 0px auto;
  //             display: block;
  //         }
  //         .item {
  //             float: left;
  //             height: 70px;
  //             margin: 0 10px;
  //         }
  //         .item img {
  //             max-height: 67px;
  //             -o-transition: all 0.2s ease-out 0s;
  //             -ms-transition: all 0.2s ease-out 0s;
  //             -moz-transition: all 0.2s ease-out 0s;
  //             -webkit-transition: all 0.2s ease-out 0s;
  //             transition: all 0.2s ease-out 0s;
  //             margin: 0px auto;
  //             display: block;
  //         }
  //     }
  // }
  .product-shop {
    // .ad-short{
    //     margin-left: 0px;
    //     margin-right: 0px;
    //     .flex {
    //     }
    //     img {
    //         margin-right: 0px;
    //         border-bottom: 1px solid $gray1;
    //         margin-bottom: 10px;
    //     }
    //     .title {
    //         color: $alpha;
    //         text-overflow: ellipsis;
    //         overflow: hidden;
    //         width: 100%;
    //         white-space: nowrap;
    //         font-size: 16px;
    //         line-height: 20px;
    //         font-weight: 700;
    //         display: inline-block;
    //         padding: 0 0 5px 10px;
    //         float: left;
    //         border-bottom: 1px solid $gray1;
    //         zoom: 1;
    //     }
    //     .stock {
    //         color: $alpha;
    //         text-overflow: ellipsis;
    //         overflow: hidden;
    //         width: 100%;
    //         white-space: nowrap;
    //         font-size: 16px;
    //         line-height: 20px;
    //         font-weight: 700;
    //         display: inline-block;
    //         padding: 5px 10px;
    //         float: left;
    //         margin-bottom: 0px;
    //         zoom: 1;
    //     }
    //     .price {
    //         color: $alpha;
    //         text-overflow: ellipsis;
    //         overflow: hidden;
    //         width: 100%;
    //         white-space: nowrap;
    //         font-size: 16px;
    //         line-height: 20px;
    //         font-weight: 700;
    //         display: inline-block;
    //         padding: 5px 10px;
    //         display: inline-block;
    //         float: right;
    //         text-align: right;
    //         zoom: 1;
    //     }
    // }
    // .order{
    //     padding: 0 0 0 10px;
    //     display: flex;
    //     flex-flow: row wrap;
    //     justify-content: flex-end;
    //     .input-group{
    //         flex: 1 1 90px;
    //         max-width: 70px;
    //         margin-right: 10px;
    //         height: 40px;
    //         border: 2px solid $gray1;
    //         background: $white;
    //         .form-control{
    //             background: none;
    //             border: none;
    //             display: flex;
    //             align-items: center;
    //             box-shadow: none;
    //             padding: 0px;
    //             text-align: center;
    //             letter-spacing: 1px;
    //         }
    //         .input-group-btn-vertical{
    //             line-height: 100%;
    //             .btn{
    //                 height: 20px;
    //                 width: 20px;
    //                 line-height: 20px;
    //                 padding: 0;
    //                 margin: 0;
    //                 border: none;
    //                 background: none;
    //                 cursor: pointer;
    //                 text-align: center;
    //                 display: block;
    //                 box-shadow: none;
    //                 &:hover {
    //                     text-decoration: none;
    //                 }
    //             }
    //             .btn i{
    //                 height: 20px;
    //                 line-height: 20px;
    //                 font-size: 20px;
    //                 text-align: center;
    //             }
    //             .btn.bottom{
    //                 margin: -5px 0 0 0;
    //             }
    //         }
    //     }
    // }
  }
}

//similar
.similar {
  h2 {
    padding: 10px;
    background: #807657;
    color: #efe09d;
    margin-top: 20px;
  }

  .related {
    padding-left: 0;
    list-style: none;

    .item-similar {
      img {
        max-width: 100%;
      }

      .info {
        background: $white;
        float: inherit;
        padding-bottom: 5px;

        .button {
          margin: 10px;
          float: right;

          .add-wishlist {
            display: inline-block;
            font-size: 16px;
            line-height: 39px;
            width: 46px;
            text-align: center;
            height: 39px;
            color: #988d66;
            background: #f2f1ec;
            padding: 0;
            margin-right: 10px;

            &:hover {
              background: $beta;
              color: $delta;
            }
          }

          .add-compare {
            display: inline-block;
            font-size: 16px;
            line-height: 39px;
            width: 46px;
            text-align: center;
            height: 39px;
            color: #988d66;
            background: #f2f1ec;
            padding: 0;

            &:hover {
              background: $beta;
              color: $delta;
            }
          }
        }
      }
    }
  }
}

.spinner {
  flex: 1 1 90px;
  max-width: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: auto;
  height: 36px;
  border: 1px solid $gray1;
  background: $white;

  .form-control {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    box-shadow: none;
    padding: 0px;
    text-align: center;
    letter-spacing: 1px;
  }

  .input-group-btn-vertical {
    line-height: 100%;

    .btn {
      height: 20px;
      width: 20px;
      line-height: 20px;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      cursor: pointer;
      text-align: center;
      display: block;
      box-shadow: none;

      &:hover {
        text-decoration: none;
      }
    }

    .btn i {
      height: 20px;
      line-height: 20px;
      font-size: 20px;
      text-align: center;
    }

    .btn.bottom {
      margin: -5px 0 0 0;
    }
  }
}

// brend //
.box-brand-filter {
  list-style: none;
  position: relative;
  overflow: hidden;
  min-height: 175px;

  .brand-filter {
    float: left;

    img {
      max-width: 100%;
    }
  }
}

// .product-img-box .small-img {}
// .product-img-box .small-img .carousel {}
.price {
  line-height: 36px;
  text-align: right;
}

.add2cart {
  width: 100%;
  @include phone {
    flex-flow: row wrap;
    justify-content: center;
    .price, .input-group, button.fa.fa-shopping-cart {
      line-height: 22px;
      width: 100%;
      flex: 1 1 100%;
      text-align: center;
      padding-left: 0;
      padding-right: 10px;
    }
    button.fa.fa-shopping-cart {
      max-width: 100%;
    }
  }
  span {
    display: block;
    text-align: end;
  }
  button.fa.fa-shopping-cart {
    flex: 1 1 200px;
    max-width: 300px;
    float: right;
    margin-right: 0;
    padding: 10px 20px;
    background: $white;
    color: $red;
    border: 1px solid $red !important;
    text-transform: none;
    font-size: 18px;
    white-space: nowrap;
    display: inline-flex;

    &:hover {
      background: $red !important;
      color: $white !important;
    }
  }
}

.bagtext{
  font-family: "Roboto",sans-serif;
  padding-left: 10px;
}

#select_color, #select_size {
  list-style: none;
  margin: 10px 0;
  padding: 10px 0;
  border-bottom: 1px dotted $epsilon;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  width: 100%;

  li {
    flex: 1 1 120px;
    max-width: 120px;
    @include phone {
      flex: 1 1 100px;
      max-width: 100px;
    }
    label {
      display: block;
      clear: both;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      font-size: 16px;
      line-height: 18px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: none;
        border: 1px solid $epsilon;
        overflow: hidden;

        &:after {
          content: "\f00c";
          font-family: "Font Awesome 5 Free";
          font-weight: bold;
          position: absolute;
          display: none;
          height: 22px;
          width: 22px;
          line-height: 22px;
          text-align: center;
        }
      }

      &:hover input ~ .checkmark {
        background-color: $red;
      }

      input:checked ~ .checkmark {
        background-color: $red;
      }

      input:checked ~ .checkmark:after {
        display: block;
        color: $white
      }
    }
  }
}

.tos {
  label {
    display: block;
    clear: both;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: none;
      border: 1px solid $epsilon;
      overflow: hidden;

      &:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        position: absolute;
        display: none;
        height: 22px;
        width: 22px;
        line-height: 22px;
        text-align: center;
      }
    }

    &:hover input ~ .checkmark {
      background-color: $red;
    }

    input:checked ~ .checkmark {
      background-color: $red;
    }

    input:checked ~ .checkmark:after {
      display: block;
      color: $white
    }
  }
}

// category //
.ad_product {
  .cat-product {
    margin-bottom: 50px;
    h2 {
      padding-left: 15px;
      font-size: 20px;
      margin: 40px 0 20px;
      color: #CA242A;
      font-weight: normal;
      text-transform: uppercase;
      @include lphone {
        font-size: 22px;
      }
    }
    .related {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-bottom: 50px;
      margin-bottom: 30px;
      .slick-dots {
        display: none !important;
      }
      button.slick-prev.slick-arrow {
        left: 15px;
        z-index: 1;
        &:before {
          font-size: 30px;
          font-family: none;
          padding: 5px;
          background-color: #eee;
        }
      }
      button.slick-next.slick-arrow {
        right: 33px;
        z-index: 1;
        &:before {
          font-size: 30px;
          font-family: none;
          padding: 5px;
          background-color: #eee;
        }
      }
      .box-cat-product {
        display: inline-block;
        border: 1px solid #e9ecef;
        padding: 10px; 
        max-width: 100%;         
        img {
          max-width: 100%;
        }
        h3 {
          color: #5E5E5E;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          a {
            color: #5E5E5E;
          }
        }
        .title-latest {
          color: #5E5E5E;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          margin: 10px 0;
          a {
            color: #5E5E5E;
            font-weight: normal;
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 80%;
            display: block;
        }
        }
        .info-ad {
          padding: 0;
          font-size: 14px;
          display: flex;
          .list-info {
            color: #5E5E5E;
            font-size: 15px;
            line-height: 25px;
            span {
              line-height: 25px;
              width: 100%;
              display: block;
              clear: both;
              font-size: 14px;
              color: #5E5E5E;
            }
            padding: 0;
            .code {
              padding: 0;
            }
            .color {
              padding: 0;
            }
            .cat {
              padding: 0;
            }
          }
          .list-right {
            padding-left: 0;
            padding-right: 0;
            .price {
            padding: 0;
            line-height: 19px;
            height: 44px;
            span {
              line-height: 25px;
              width: 100%;
              display: block;
              clear: both;
              font-size: 14px;
              color: #5E5E5E;
            }
            }
            .brand {
              padding: 0;
              min-height: 38px;
            }
            .color {
              padding: 0;
              text-align: right;
              line-height: 14px;
              min-height: 38px;
              letter-spacing: -0.05em;
              span {
                line-height: 25px;
                width: 100%;
                display: block;
                clear: both;
                font-size: 14px;
                color: #5E5E5E;
              }
            }
          }
        }
      }
    }
  }
}