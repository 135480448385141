@mixin mosaic-header {
  h2 {
    font-size: 32px;
    margin: 3rem 0 1rem;
  }
}

.no-margin {
  margin-left: 0;
  margin-right: 0;
}

@mixin title-holder {
  .title-holder {
    text-align: center;
    color: $alpha;
    padding: 22px 0 100px 0;

    p {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .text {
      padding-top: 50px;
      font-size: 20px;
      //max-width: 700px;
      //line-height: 26px;
      margin: auto;
    }
  }
}

#website-index main > section {
  margin: 0;
  padding: 0px !important;
  &.box3,&.index_cols,&.plain_image{
    padding: 50px 0px!important;
  }
}

.hero-text {
  text-align: center;
  /*  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
  position: relative;
  color: $white;
  @include hd-desktop {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }

  h1 {
    color: $white;
  }

  .btn {
    font-size: 18px;
    color: #fff;
    margin: 5px;
    padding: 7px 22px;
    text-decoration: none;
    border: 1px solid $zeta;
    border-radius: 25px;

    &.btn-link1 {
      background: $zeta;
    }

    &.btn-link2:hover {
      background: $zeta;
    }
  }
}

.segment_title {
  @include mosaic-header;
  @include title-holder;
  .title-holder {
    padding: 60px 15px;
  }
}

.teaser {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  min-height: 450px;
  height: 50vh;
  position: relative;

  .container {
    position: relative;
  }


}

.animation {
  padding: 0 !important;
  position: relative;
  //max-height: 895px;
  //overflow: hidden;
  //top: -275px;
  //margin-bottom: -335px !important;
  .container {
    position: relative;
  }

  .slick-slider > .item:not(:first-child) {
    display: none;
  }

  .container {
    padding: 0;
  }

  .container-fluid {
    padding: 0;

    .slick-slider {
      height: auto;
      margin-bottom: 0px;
      max-height: 600px;
      @include phone {
        top: 10px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }

    .slick-prev {
      background: url(../images/skins.png) no-repeat 0 0;
      height: 150px;
      width: 35px;
      left: 25px;
      z-index: 1;
      @include lphone {
        height: 50px;
        background-size: cover;
      }

      &:before {
        font-family: '';
        content: '';
      }
    }

    .slick-next {
      right: 25px;
      z-index: 1;
      background: url(../images/skins.png) no-repeat -1790px 0;
      height: 150px;
      width: 35px;
      @include lphone {
        height: 50px;
        background-size: cover;
      }

      &:before {
        font-family: '';
        content: '';
      }
    }

    // .slick-dots {
    //   display: none !important;
    // }
  }

  .item {
    position: relative;
    max-height: 600px;
    /*max-height: 895px;*/
    a.full_item_link{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: transparent;
    }
    .hero-text {
      position: absolute;
      color: $black;
      max-width: 1140px;
      margin-left: 0;
      margin-right: 0;
      opacity: 0;
      transition: all 3s;
      top: 2%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 100%;

      h1 {
        font-size: 52px;
        font-family: 'Quicksand', sans-serif;
        font-weight: 700;
        line-height: 58px;
        text-align: left;
        color: $black;
        margin-bottom: 10px;
        color: #fff;
        @include lphone {
          font-size: 26px;
          text-align: center;
        }
      }

      p {
        font-weight: 400;
        letter-spacing: 0.09em;
        position: absolute;
        max-width: 700px;
        border-bottom: 1px solid #aeaeae;
        border-top: 1px solid #aeaeae;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      .image2 {
        width: 100%;
        max-width: 700px;
        height: auto;
      }

      .buttom {
        margin-top: calc(30px + 50px);
      }
    }
  }

  picture{
    max-height: inherit!important;
  }

  img.bg-image {
    font-family: 'object-fit: cover;';
    -o-object-fit: cover;
    object-fit: cover;
  }

  .bg-image {
    //position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    max-height: inherit!important;
    // transition-duration:1s;
    // transition-property:transform;
    //transform:scale(1.05) translate(0);
    // transform-origin:left top 0
  }

  .slick-active {
    .hero-text {
      opacity: 1;
      top: 2%;
      // transition: all 3s;
      animation: animation-herotext 3s;
    }

    .bg-image {
      // transition-duration:4s;
      // transition-property:transform;
      // transform:scale(1) translate(0);
      animation: animation-image 3s;
    }
  }

}

.animation, .teaser {
  .newsletter {
    position: absolute;
    right: 70px;
    top: 50px;
    max-width: 230px;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 1.5rem;
    padding: 20px;
    @include lphone {
      position: relative;
      top: 10px;
      left: auto;
      right: auto;
      display: block;
      margin: 0 auto 20px;
      width: 100%;
      padding: 10px 0;
      max-width: 320px;
    }
    @include tablet-only {
      top: 10px;
    }

    h4 {
      font-size: 16px;
      text-align: center;
      line-height: 20px;
    }

    h3 {
      text-align: center;
      font-weight: 400;
      color: $red;
      text-transform: uppercase;
      font-size: 25px;
      font-weight: light;
      margin-bottom: 0px;
    }

    p {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    form {
      input {
        width: 100%;
        background: $white;
        padding: 5px;
        margin-bottom: 10px;
        text-transform: none;
        border: 1px solid #ccd0d4;

        &[type="submit"] {
          background: $red;
          color: $white;
          max-width: 70%;
          display: block;
          margin: 0 auto;
          text-align: center;
          border: none;
        }
        &::placeholder {
          text-transform: unset;
        }
      }
    }
  }
}

.videobg {
  position: relative;
  overflow: hidden;

  video {
    display: block;
  }

  /* .video-background {
       filter: blur(1px);
   }*/
}

#mos0_videobg {
  z-index: -1;
  margin-top: -8rem !important;
}

@media (min-aspect-ratio: 16/9) {
  .video-background {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .video-background {
    width: auto;
    //height: 100%;
    height: 100vh;
  }
}

.paralax {
  position: relative;

  .parallax-band {
    padding: 60px 0;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .row {
      margin: 0 auto;
      display: block;
      align-items: center;
      h3 {
        text-align: center;
      }
    }

    @include hd-desktop {
      height: auto;
      &.container-fluid {
        margin: 0 auto;
      }
    }

    .left {
      float: left;
    }
  }

  &.vision {
    .hero-text {
      p {
        padding-left: 200px;
        color: $alpha;
        @include tablet {
          padding-left: 50px;
        }
        @include lphone {
          padding-left: 0;
        }
      }
    }

    h2 {
      color: $red;
      @include desktop-up {
        position: absolute;
        top: 75px;
        right: -100px;
        transform: rotate(-90deg);
        font-size: 48px;
      }
      font-size: 36px;
      text-transform: uppercase;
    }

    a.btn {
      display: none;
    }
  }

  &.mission {
    background: $white;

    .parallax-band {
      background: $white !important;
    }

    .hero-text {
      p {
        padding-right: 200px;
        color: $alpha;
        @include tablet {
          padding-right: 50px;
        }
        @include lphone {
          padding-right: 0;
        }
      }
    }

    h2 {
      color: $red;
      @include desktop-up {
        position: absolute;
        top: 125px;
        left: -100px;
        transform: rotate(-90deg);
        font-size: 48px;
      }
      font-size: 36px;
      text-transform: uppercase;
    }
  }

  &.partners {

    .parallax-band {
      height: 800px;
    }
  }
}

.parallax-us {
  position: relative;

  .parallax-band {
    padding: 60px 0;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .row {
      max-width: 75rem;
      display: block;
      margin: 0 auto;
      align-items: center;
      @include hd-desktop {
        max-width: 60rem;
      }

    }

    .hero-text {
      display: block;
      justify-content: flex-start;
      flex-flow: row nowrap;
      color: #000;
      @include tablet {
        flex-wrap: wrap;
      }
      h4 {
        margin: 20px 0 0 !important;
        &::first-letter {
          color: #CA242A;
      }
      }
    }
  }
}

.box-holder {
  .partneri-info {
    display: block;
    margin: auto auto 20px;
    max-width: 1140px;
}
}
.partner {
  h2 {
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    color: #5e5e5e;
    margin-bottom: 20px;
  }
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  .item {
    margin: 0 5px;
  }
  .container {
    background: $white;
    max-width: 90%;
    margin: 0 auto;
    .slick-dotted.slick-slider {
      margin-bottom: 0;
    }
    .slick-dots {
      display: none !important;
    }
    button.slick-prev.slick-arrow {
      background: url(../images/skins-1.png) no-repeat 0 0;
      width: 35px;
      z-index: 1;
      height: 100px;
      &:before {
        content: '';
        font-family: '';
      }
    }
    button.slick-next.slick-arrow {
      background: url(../images/skins-1.png) no-repeat -1785px 0;
      width: 35px;
      z-index: 1;
      height: 100px;
      &:before {
        content: '';
        font-family: '';
      }
    }
  }
}

.diler {
  background-image: url(../images/background-diler.jpg);

  .dileri {
    padding-bottom: 80px;
    .row.dil {
      margin-left: 0;
      margin-right: 0;
      .distributer {
        background-color: #00000060;
        padding: 100px;
        color: #fff;
        @include tablet {
          padding: 100px 40px;
        }
        img.logo {
          position: relative;
          z-index: 1;
          top: -50px;
          float: right;
        }
        img.img-fluid.float-left {
          position: relative;
          @include lphone {
            margin: 20px auto;
          }
        }
      }
    }

  }

  .form {
    margin-top: 30px;

    .upit {
      max-width: 350px;
      float: right;
      background-color: rgba(255, 255, 255, 0.85);
      border-radius: 1.5rem;
      padding: 40px 40px 20px;
      margin-bottom: 50px;

      h3 {
        text-align: center;
        font-weight: 400;
      }
    }
  }
}

.imghover {
  //background: $white;
  .col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .item {
    position: relative;
    width: 300px;
    height: 300px;
    background: #ff0;
    margin: 10px;
    box-sizing: border-box;
    display: inline-block;

    .imgBox {
      position: relative;
      overflow: hidden;

      img {
        max-width: 100%;
        transition: transform 2s;
      }
    }
  }

  .details {
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.8);
    transform: scaleY(0);
    transition: transform .5s;
  }

  .item:hover {
    .imgBox img {
      transform: scale(1.2);
    }

    .details {
      transform: scaleY(1);
    }
  }

  .details .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding: 15px;
    color: #fff;
  }

  .details .content h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: #ff0;
  }

  .details .content p {
    margin: 10px 0 0;
    padding: 0;
  }
}

.flipcard {
  background: $white;

  .col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .card {
    position: relative;
    margin: 20px 0;
    width: 300px;
    height: 400px;
    background: #fff;
    transform-style: preserve-3d;
    transform: perspective(2000px);
    transition: 1s;
    box-shadow: inset 300px 0 50px rgba(0, 0, 0, .5);
  }

  .card:hover {
    z-index: 1000;
    transform: perspective(2000px) rotate(-10deg);
    box-shadow: inset 20px 0 50px rgba(0, 0, 0, .5);
  }

  .card .imgBox {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    box-sizing: border-box;
    transform-origin: left;
    z-index: 1;
    transition: 1s;
  }

  .card:hover .imgBox {
    transform: rotateY(-135deg);
  }

  .card .imgBox .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }

  .card .details {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 20px;
  }
}

.counter {
  @include mosaic-header;
  text-align: center;

  .container {
    background: $white;
  }

  .item {
    //box-shadow: 1px 2px 10px 0px rgba(128, 128, 128, 0.349);
    .number {
      font-size: 4rem;
      color: $zeta;
    }

    p {
      text-align: center;
    }
  }
}

.box2 {
  @include mosaic-header;

  .container {
    background: $white;
  }

  .item {
    text-align: center;
    padding: 4rem;
    }
}



.box4 {
  @include mosaic-header;
  @include title-holder;

  .container {
    background: $white;
  }

  .item {
    text-align: center;

    p.text-center {
      text-align: center
    }
  }

  .fas {
    font-size: 52px;
  }
}

.img-left, .img-right {
  @include mosaic-header;

  .container .card {
    background: $white;
    height: 100%;
    padding: 45px;
  }

  .img-holder {
    text-align: center;
  }
}

.bullets2col {
  @include mosaic-header;
  @include title-holder;
  margin: 30px 0 !important;
  a {
    color: #000;
  }
  .title-holder {
    text-align: center;

    p {
      text-align: center;
    }
  }

  .container {
    //background: $white;
    .left {
      text-align: end;
      margin-top: 30px;
      @include lphone {
        text-align: center;
      }
      h3 {
        color: #da0000;
      }
    }

    .right {
      margin-top: 30px;
      @include lphone {
        text-align: center;
      }
      h3 {
        color: #da0000;
      }
      ul {
        padding-left: 0;
      }
    }

    ul {
      margin-top: 3rem;

      li {
        list-style: none;
        padding: 3px;
      }
    }
  }
}

.testimonial {
  @include mosaic-header;
  @include title-holder;

  .item {
    padding: 30px;
    margin: 15px;
    background: $white;
    text-align: center;

    .rounded-circle {
      border-radius: 50% !important;
      margin: 0 auto;
    }
  }
}

.contact {
  @include mosaic-header;
  @include title-holder;

  .container {
    background: $white;
  }

  .item {
    text-align: center;
  }

  .fas {
    font-size: 52px;
  }
}

.box-holder {
  .container {
    background: $white;
  }
}

.history {
  background-image: url(http://server.webbox.rs/dev/albo-shop/files/istorija.jpg);
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
  height: 1050px;
  width: 1650px;
  border-radius: 50px;
  border: 2px solid #c5cfd2;
  margin: auto;
  //margin-left: 105px;
  .item-history {
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;

    h4 {
      color: #ca242a;
      font-weight: bold;
      font-size: 25px;
    }

    p {
      font-size: 12px;
      width: max-content;
      color: #000;
      line-height: normal;
    }

  }

  #item-history-0 {
    top: 65px;
    left: 55px;
  }

  #item-history-1 {
    top: -40px;
    left: 280px;
  }

  #item-history-2 {
    top: 180px;
    left: 300px;
  }

  #item-history-3 {
    top: -25px;
    left: 540px;
  }

  #item-history-4 {
    top: 274px;
    left: 655px;
  }

  #item-history-5 {
    top: -15px;
    left: 850px;
  }

  #item-history-6 {
    top: 300px;
    left: 935px;
  }

  #item-history-7 {
    top: 80px;
    left: 1000px;
  }

  #item-history-8 {
    top: -20px;
    left: 1220px;
  }

  #item-history-9 {
    top: 140px;
    left: 1425px;
  }

  #item-history-10 {
    top: 585px;
    left: 1430px;
  }

  #item-history-11 {
    top: 770px;
    left: 1240px;
  }

  #item-history-12 {
    top: 805px;
    left: 995px;
  }

  #item-history-13 {
    top: 520px;
    left: 865px;
  }

  #item-history-14 {
    top: 830px;
    left: 775px;
  }

  #item-history-15 {
    top: 520px;
    left: 555px;
  }

  #item-history-16 {
    top: 860px;
    left: 355px;
  }

  #item-history-17 {
    top: 655px;
    left: 85px;
  }

  #item-history-18 {
    top: 405px;
    left: 5px;
  }
}

section.box2 {
  .item {
    padding: 15px !important;
  }

  h3 {
    text-align: left;
    font-weight: bold;
    color: $red;
    font-size: 20px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-left: 20px;
      margin: 0;
      position: relative;
      line-height: 25px;
      text-align: left;
      margin-bottom: 10px;

      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        left: 0;
        top: 5px;
        background: $red;
      }

      ul {
        margin-top: 10px;

        li:before {
          border-radius: 5px;
        }
      }
    }
  }
}

#website-index .segment_title .title-holder p {
  text-align: center;
}

#mos1_segment_title, #mos1_segment_title_about {
  h3 {
    font-weight: 600;
  }

  .title-holder p {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 10px; 
    text-align: justify;
    &:last-child {
      margin-bottom: 0;
    } 
  }
}

#mos4_paralax_us {
  h2 {
    font-weight: 300;
    text-align: center;
    margin: 20px 0 50px;
    line-height: 50px;
  }

  h4 {
    text-align: left;
    font-weight: bold;
    margin: 20px 0 10px;
  }
}


#iframe-regulativa {
  width: 100%;
  min-height: 300px !important;
  height: 400px !important;
  overflow-y: auto;
}

section.box3{
  padding: 50px 0px!important;
  h3{
    text-align: center;
    margin-bottom: 15px;
    a{
      color: #212121;
      transition: all .2s;
      &:hover{
        color: #da0000;
      }
    }
  }
  img{
    width: 100%;
    margin-bottom: 15px;
  }
  a.btn-red{
    color: #da0000;
    display: block;
    width: fit-content;
    margin: auto;
  }
}

section.index_cols{
  h3{
    text-align: center;
    margin-bottom: 15px;
    a{
      color: #212121;
      transition: all .2s;
      &:hover{
        color: #da0000;
      }
    }
  }
  .item{
    text-align: center;
    img{
      max-width: 100%;
    }
  }
}

.plain_image{
  img{
    max-width: 100%;
    width: 100%;
  }
}

.box2-row{
  .item:last-child{
    p,h3,iframe{
      display: none;
    }
    a{
      display: block;
      max-height: 450px;
      overflow-y: auto;
      img{
        max-width: 100%;
        width: 100%;
      }
    }
  }
}