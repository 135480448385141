/**
* Copyright info-g 
* author:agvozden
* www.gvozden.info
*/
@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,900&display=swap');

// variables
@import "variables";


// mixins
//@import "mixins/clearfix";
@import "theme/responsive";

// base
//@import "base/normalize";
//@import "base/reset";

// theme
@import "theme/body";
@import "theme/header";
@import "theme/footer";
@import "theme/main";
//@import "theme/fonts";

@import "theme/nav";
@import "theme/breadcrumbs";
@import "theme/boxes";
@import "theme/member";
@import "theme/forms";
@import "theme/categories";
@import "theme/product";
@import "plugins/order";
//@import "plugins/slider";
@import "plugins/mosaic";
//@import "plugins/wishlist";
//@import "plugins/compare";
//@import "plugins/gallery";
@import "plugins/floatbox";

//@import "theme/social";
@import "theme/search";
@import "theme/buttons";
@import "theme/news";
@import "theme/pagination";


