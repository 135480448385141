/* RESPONSIVE */
$phone-width: 576px;
$lphone-width: 768px;
$tablet-width: 992px;
$desktop-width: 1200px;
$wide-desktop-width: 1280px;
$hd-desktop-width: 1366px;
$hdp-desktop-width: 1600px;
$fhd-desktop-width: 1920px;
$qhd-desktop-width: 2560px;
$uhd-desktop-width: 3880px;

/* Phone portrait */
@mixin phone {
  @media (max-width: #{$phone-width - 1px}) {
    @content;
  }
}
@mixin phone-up {
  @media (min-width: #{$phone-width + 1px}) {
    @content;
  }
}
/* Phone landscape */
@mixin lphone {
  @media (max-width: #{$lphone-width - 1px}) {
    @content;
  }
}
@mixin lphone-up {
  @media (min-width: $lphone-width ) {
    @content;
  }
}
@mixin lphone-only {
  @media (min-width: $phone-width) and (max-width: $lphone-width) {
    @content;
  }
}
/* Bigger phone landscape */
@mixin lphone-big {
  @media (min-width: $lphone-width) and (max-width: $tablet-width) and (orientation:landscape)  {
    @content;
  }
}
/* Tablet portrait */
@mixin tablet {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}
@mixin tablet-up {
  @media (min-width: $tablet-width ) {
    @content;
  }
}
@mixin tablet-only {
  @media (min-width: $lphone-width) and (max-width: $tablet-width) {
    @content;
  }
}
/* Tablet landscape and small desktop - 1200px width */
@mixin desktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin desktop-up {
  @media (min-width: $desktop-width ) {
    @content;
  }
}
@mixin desktop-only {
  @media (min-width: $tablet-width) and (max-width: $desktop-width) {
    @content;
  }
}
/* Wide desktop - 1280px width */
@mixin wide-desktop {
  @media (max-width: #{$wide-desktop-width - 1px}) {
    @content;
  }
}
@mixin wide-desktop-up {
  @media (min-width: $wide-desktop-width ) {
    @content;
  }
}
@mixin wide-desktop-only {
  @media (min-width: $desktop-width) and (max-width: $wide-desktop-width) {
    @content;
  }
}
/* HD desktop - 1366px width */
@mixin hd-desktop {
  @media (max-width: #{$hd-desktop-width - 1px}) {
    @content;
  }
}
@mixin hd-desktop-up {
  @media (min-width: $hd-desktop-width ) {
    @content;
  }
}
@mixin hd-desktop-only {
  @media (min-width: $wide-desktop-width) and (max-width: $hd-desktop-width) {
    @content;
  }
}

/* HD+ desktop  - 1600px width*/
@mixin hdp-desktop {
  @media (max-width: #{$hdp-desktop-width - 1px}) {
    @content;
  }
}
@mixin hdp-desktop-up {
  @media (min-width: $hdp-desktop-width ) {
    @content;
  }
}
@mixin hdp-desktop-only {
  @media (min-width: $hd-desktop-width) and (max-width: $hdp-desktop-width) {
    @content;
  }
}

/* FHD desktop  - 1920px width*/
@mixin fhd-desktop {
  @media (max-width: #{$fhd-desktop-width - 1px}) {
    @content;
  }
}
@mixin fhd-desktop-up {
  @media (min-width: $fhd-desktop-width ) {
    @content;
  }
}
@mixin fhd-desktop-only {
  @media (min-width: $hdp-desktop-width) and (max-width: $fhd-desktop-width) {
    @content;
  }
}
/* QHD desktop / 2K - 2560px width*/
@mixin qhd-desktop {
  @media (max-width: #{$qhd-desktop-width - 1px}) {
    @content;
  }
}
@mixin qhd-desktop-up {
  @media (min-width: $qhd-desktop-width ) {
    @content;
  }
}
@mixin qhd-desktop-only {
  @media (min-width: $fhd-desktop-width) and (max-width: $qhd-desktop-width) {
    @content;
  }
}
/* UHD desktop / 4K - 3880px width*/
@mixin uhd-desktop {
  @media (max-width: #{$uhd-desktop-width - 1px}) {
    @content;
  }
}
@mixin uhd-desktop-up {
  @media (min-width: $uhd-desktop-width ) {
    @content;
  }
}
@mixin uhd-desktop-only {
  @media (min-width: $qhd-desktop-width) and (max-width: $uhd-desktop-width) {
    @content;
  }
}
