#boxSearchHolder {
	top: 143px;
	right: 20px;
	position: absolute
}

#boxSearch {
	width: 280px;
	img {width:15px;height:17px;vertical-align:text-bottom}
	input[type=image]{width:63px;height:19px;vertical-align:text-bottom}
}

#website-search{
	background-color: #e9ecef;
	.blog-post {
		.search-view {
			background-color: #fff;
		}
		margin-top: 40px;
		h2 {
			padding: 20px 10px;
			span {
				display: block;
				font-size: 14px;
			}
		}
		img {
			margin-bottom: 20px;
		}
	}
}

article.category-search {
	.blog-post {
		.search-view {
			background-color: #fff; 
			padding: 10px;
			.flex {
			}
	  
			&-image {
			  a {
				overflow: hidden;
				display: block;
				max-height: 308px;
				width: 100%;
			  }
	  
			  img {
				margin: 0 auto 10px;
				/*border-bottom: 1px solid $gray1;*/
				max-width: 100%;
				max-height: 300px;
	  
				&:hover {
				  -webkit-transform: scale(1.1);
				  transform: scale(1.1);
				  -moz-transform: scale(1.1);
				  -o-transform: scale(1.1);
				  opacity: 0.3;
				  filter: alpha(opacity=30);
				  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
				  transition: all 0.45s ease;
				}
			  }
			}
	  
			.title {
			  display: block;
			  color: $beta;
			  width: 100%;
			  font-size: 16px;
			  line-height: 20px;
			  font-weight: 700;
			  zoom: 1;
			  height: 75px;
			}
	  
			.stock {
			  color: $alpha;
			  text-overflow: ellipsis;
			  overflow: hidden;
			  width: 100%;
			  white-space: nowrap;
			  font-size: 16px;
			  line-height: 20px;
			  font-weight: 700;
			  display: inline-block;
			  padding: 5px 10px;
			  float: left;
			  margin-bottom: 0px;
			  zoom: 1;
			}
	  
			.price {
			  display: block;
			  margin-bottom: 10px;
			  min-height: 58px;
			  color: #04c836;
			  font-size: 16px;
			  line-height: 18px;
			  padding: 0;
			  float: right;
			  .old-price {
				color: #c3c3c3;
				text-decoration: line-through;
				white-space: pre;
			  }
			}
			.code {
			  letter-spacing: -0.09em;
			  padding: 0;
			  margin-bottom: 10px;
			}
			.color {
			  padding: 0;
			  margin-bottom: 10px;
			}
			.cat {
			  letter-spacing: -0.05em;
			  padding: 0;
			  margin-bottom: 10px;
			  float: left;
			  min-height: 72px;
			}
			.brand {
			  height: auto;
			  padding: 0;
			  display: inline-block;
			  .logo-img {
				width: calc(100% - 20px);
			  }
			  .logo-brend {
				width: 30px;
				margin: auto;
				position: absolute;
				right: 0px;
				margin-top: 5px;
				}
			}
	  
	  
			.info-ad {
			  margin: 10px 0;
			  padding: 0;
			  min-height: 40px;
			  line-height: 18px;
			  display: flex;
    			justify-content: flex-start;
    			flex-flow: row wrap;
			  .col-6 .col-7 {
				@include lphone {
				  padding: 0 5px;
				  margin-bottom: 10px;
				}
			  }

			  span {
				line-height: 20px;
				width: 100%;
				display: block;
				clear: both;
				font-size: 13.5px;
				color: #000;
				letter-spacing: normal;
			  }
	  
			  .title {
				display: block;
				width: 100%;
				font-size: 16px;
				line-height: 18px;
				font-weight: 700;
				zoom: 1;
				height: 73px;
				overflow: hidden;
				margin-bottom: 10px;
				padding: 15px 0;
				border-top: 1px solid #000;
				a {
					color: #5E5E5E;
					font-weight: normal;
				    font-size: 16px;
    				letter-spacing: -0.05em;
				}
			  }
	  
			  .price {
	  
				.old-price {
				  color: #c3c3c3;
				  text-decoration: line-through;
				  white-space: pre;
				  font-weight: 600;
				}
			  }
			}
		}
	}
}