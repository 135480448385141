.breadcrumb-box {
    background:#5E5E5E;
    .breadcrumb {
        border-radius: 0;
        background: $beta;
        color: $gama;
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 0;
    
        .breadcrumb-item {
            a {
                color: $gama;
            }
    
            &.active {
                color: $white;
            }
        }
    }
}

ol.breadcrumb {
    display: none !important;
}
