html {
  scroll-behavior: smooth;
}

body {
  font-family: $font, $fontr2;
  font-size: 16px;
  line-height: 24px;
  color: $alpha;
  //background-image: url('../images/background.jpg');
  //background-color: #eee;
  background-repeat: no-repeat;
  //background-size: 100%;
  background-size: cover;
  //perspective: 1px;
  //transform-style: preserve-3d;
  //height: 100vh;
  //overflow-x: hidden;
  //overflow-y: scroll;
}

strong {
  font-weight: 600;
}

h1 {
  color: $red;
  font-family: $font, $fontr1;
  font-size: 29px;
  font-weight: bold;
  line-height: 55px;
  margin: 20px 0 40px !important;

  span {
    font-weight: normal;
  }
}

a {
  color: $beta;

  &:hover {
    color: $beta;
    text-decoration: none;
  }
}

p {
  text-align: justify;
  &:last-child {
    margin-bottom: 0;
  }
}

section > article {
  padding: 10px
}

article.page,
article.category,
article.news,
article.ad {
  max-width: 100%;
  overflow: hidden;
/*  background: #f7f7f7;*/
  .product-category-view {
    margin: 0 -15px;
  }

  img {
    max-width: 100%;
  }

  h1 {
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-size: 20px !important;
    color: #000;
  }

  section {
    margin: 0;
    padding: 0;
  }
}

article.news{
  .full_preview_img{
    text-align: center;
  }
  section.news_text{
    padding: 30px 0px;
  }
}

article.news{
  text-align: center;
  h1{
    color: #da0000;
    font-size: 24px;
  }
  img{
    display: block;
    margin: 15px auto;
    max-width: 100%;
    width: auto;
  }
  p{
    text-align: center;
  }
}

article.category {
  h1 {
    margin-bottom: 0;
    margin-top: 0px!important;
  }
}
.cat-ad-list.list-level h3.opis-level {
  display: none;
}

.highlight {
  background: $epsilon;
  padding: 10px;
}

.ad .highlight {
  padding: 20px;
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

hr.style13 {
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8b8b inset;
  color: #cc0000;
}

hr.divider {
  margin: 20px 0 30px 0;
  border-bottom: 4px dotted $dotted;
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8b8b inset;
  color: #cc0000;
}

.ads-show {
  position: fixed;
  bottom: 0;
  right: 0;
  display: block;
  z-index: 99998;
  i {
    position: absolute;
    right: 10px;
    top: 12px;
    font-size: 22px;
  }
  img {
    width: 100%;
    max-width: 450px;
    @include lphone {
      max-width: 300px;
    }
  }
}

#website-index .fl-show {
  display: none;
}
.fl-show {
  position: fixed;
  top: 250px;
  right: 0;
  display: block;
  z-index: 99999;
  .fl-ico {
    width: 54px;
    height: 205px;
    background: url(../images/Ikonica-za-bocni-pop-up.png) center center no-repeat;
    position: relative;
    right: 0;
    -o-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    &:hover {
      right: 287px;
    }
    .fl-block {
      width: 287px;
      height: 420px;
      background: #fff;
      position: absolute;
      display: block;
      top: 0;
      margin-left: 54px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 10px;
    }
  }
}

.transparent_letters{
  color: transparent!important;
}

h3.center_title{
  text-align: center;
}
a.ad_list_pdf_link{
  text-decoration: underline;
  color: #da0000;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  h1 {
    /*text-transform: uppercase;*/
    span {
      font-weight: normal;
      display: block;
    }
  }

}

article.category h1 {
  display: none;
  &.show-title{
    display: block;
    color: #da0000;
    padding-top: 20px;
    margin-bottom: 20px!important;
    text-align: center;
  }
}


.container {
  @include lphone-only {max-width: 100%}
}

.padding-top-btm{
  padding: 50px 0px!important;
  img{
    max-width: 100%;
  }
}

.items-row{
  margin: 0;
  padding: 50px 0px;
  .pdf-outer{
    margin-bottom: 24px;
  }
  .pdf-wrapper{
    background: #fff;
    box-shadow: 0 5px 20px rgba(0,0,0,.1);
    height: 100%;
    transition: all .3s;
    &:hover{
      transform: translateY(-4px);
    }
  }
  .img-wrapper{
    overflow: hidden;
    a{
      img{
        max-width: 100%;
        width: 100%;
        transition: all .3s ease-out;
      }
    }
    &:hover{
      a{
        img{
          transform: scale(1.1);
        }
      }
    }
  }
  .text-wrapper{
    padding: 10px;
    text-align: center;
    h3{
      font-size: 16px;
      a{
        color: #212121;
        transition: all .2s;
        &:hover{
          color: #da0000;
        }
      }
    }
    h5{
      color: #5e5e5e;
    }
  }
}

article.page{
  strong{
    color: #da0000;
  }
}

span.code{
  text-transform: uppercase;
}