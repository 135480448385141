h3.cart-title {
  margin: 20px 0;
  line-height: 40px;
  text-transform: uppercase;

  i {
    margin-right: 5px;
  }
}

#cart {
  padding: 0;
  h2 {
    padding-top: 30px;
  }
  .user {
    float: right;
  }

  .row.justify-content-end {
    justify-content: flex-end !important;
    margin-bottom: 15px;

    .back {
      margin: 20px;
      background: $epsilon;
      @include lphone {
        margin: 20px auto;
      }

      a {
        color: $alpha;
        line-height: 50px;
        padding: 20px;
        text-transform: uppercase;
        margin: 20px;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background: $alpha;

        a {
          color: $white;
        }
      }
    }

    .next {
      margin: 20px 0;
      background: $white;
      border: 1px solid $red;
      color: $red;
      @include lphone {
        margin: 20px auto;
      }

      a {
        color: $red;

        line-height: 50px;
        padding: 20px;
        text-transform: uppercase;
        margin: 20px;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background: $red;

        a {
          color: $white;
        }
      }
    }
  }

  .step {
    border-bottom: 1px solid $gray1;

    .col {
      position: relative;
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 20px;
      padding: 20px 1px;
      @include lphone {
        display: block;
        font-size: 14px;
        i, span {
          display: block;
          clear: both;
          margin: 10px auto;
        }
        i {
          font-size: 24px;
        }
      }
    }

    .col.done, .col.current {
      color: $red;
    }
  }

  .shopping-cart {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    clear: both;
    width: 100%;

    li {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: center;
      padding: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);

      &:nth-child(2n) {
        background: rgba($epsilon, 0.25);
      }

      .total {
        border-left: none;
        display: none;
      }
      .price {
        display: none;
      }

      .quantity {
        flex: 1 1 200px;
        //max-width: 200px;
        max-width: calc(100% / 3);
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        @include phone {
          max-width: calc(100% / 2);
        }
        a {
          margin: 0 5px;
        }

        .fa-undo {
          color: $green;
        }

        .fa-times-circle {
          color: $red;
        }

        input {
          border: 1px solid $epsilon;
          transition: all 0.5s ease-in-out;

          &:focus {
            outline: none;
            border: 1px solid $alpha;
          }
        }
      }

      span {
        flex: 1 1 120px;
        max-width: 150px;
        line-height: 50px;
        text-align: center;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        padding: 5px 0;
        display: flex;
        justify-content: center;
        flex-flow: row nowrap;
        align-items: center;
        @include phone {
          max-width: 120px;
        }

        &.vat {
          @include phone {
            display: none;
          }
        }

        &.total {
          justify-content: flex-end;
          text-align: right;
          padding-right: 20px;
        }

        input {
          margin: 0 10px;
          max-width: 80px;
          line-height: 28px;
          height: 30px;
          text-align: center;
        }

        .image img {
          width: auto;
          max-width: 90%;
          max-height: 50px;
          display: block;
          margin: 0 auto;
        }

        .title {
          flex: 1 1 100%;
          max-width: calc(100% - 680px);
          font-weight: bold;
          text-align: left;
          line-height: 30px;
          padding-left: 10px;
        }

        .image {
          flex: 1 1 120px;
          max-width: 120px;
          display: flex;
          justify-content: center;
          flex-flow: row nowrap;
          align-items: center;

          img {
            width: auto;
            max-width: 90%;
            max-height: 50px;
            display: block;
            margin: 0 auto;
          }
        }
      }

      .image {
        flex: 1 1 120px;
        //max-width: 120px;
        max-width: calc(100% / 3);
        @include phone {
          display: none;
        }
        img {
          width: auto;
          max-width: 90%;
          max-height: 50px;
          display: block;
          margin: 0 auto;
        }
      }

      .title {
        flex: 1 1 100%;
        //max-width: calc(100% - 680px);
        max-width: calc(100% / 3);
        text-align: left;
        line-height: 20px;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        flex-flow: row nowrap;
        align-items: center;
        @include phone {
          max-width: calc(100% / 2);
        }
        @include lphone-only {
          flex: 1 1 300px;
          max-width: 300px;
          overflow: hidden;
        }

        a {
          display: flex;
          justify-content: flex-start;
          flex-flow: row wrap;

        }

        span.name {
          flex: 1 1100%;
          max-width: 100%;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 10px;
        }

        span {
          flex: 0 auto;
          margin-right: 10px;

          i {
            margin-right: 5px;
          }
        }

        a {
          color: $alpha;

          &:hover {
            color: $red;
          }
        }

        span {
          font-weight: normal;
          font-size: 14px;
          display: block;
          max-width: 100%;
          line-height: 20px;
          text-align: left;
          color: #676767;
          border: none;
          padding: 0;
          text-transform: uppercase;
        }
      }
    }

    li.total {
      border-bottom: none;
      background: none;
      display: none;
      &:nth-child(2n) .sum span {
        background: rgba($epsilon, 0.25);
      }

      .note {
        color: #93C11F;
        flex: 1 1 300px;
        max-width: 300px;
        border: none;
        justify-content: flex-end;
      }

      .continue {
        text-align: end;
        justify-content: flex-end;
        flex: 1 1 auto;
        max-width: calc(100% - 620px);

        a {
          background: #00007F;
          color: $white;
          line-height: 60px;
          padding: 0 20px;
          text-transform: uppercase;
          margin-right: 20px;
        }
      }

      .sum {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        flex: 1 1 440px;
        max-width: 440px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
        padding: 0;

        span:nth-child(1) {
          flex: 1 1 200px;
          max-width: 200px;
          padding-right: 20px;
          text-align: right;
          justify-content: flex-end;
        }

        span:nth-child(2) {
          flex: 1 1 240px;
          max-width: 240px;
          text-align: right;
          padding-right: 20px;
          border-right: none;
          justify-content: flex-end;
        }
      }
    }

    li {
      @include lphone-only {
        flex-wrap: nowrap !important;
        span.image {
          display: block;
          flex: 1 1 120px;
          max-width: calc(100% / 4);
        }
      }
    }

    li.header {
      background: $epsilon;
      color: $alpha;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0;

      .title {
        line-height: 50px;
        max-width: calc(100% / 3);
        @include phone {
          max-width: calc(100% / 2);
        }
        @include lphone-only {
          max-width: calc(100% / 2);
        }
      }

      .image {
        flex: 1 1 120px;
        //max-width: 120px;
        max-width: calc(100% / 3);
      }

      .quantity {
        flex: 1 1 200px;
        //max-width: 200px;
        max-width: calc(100% / 3);
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        @include phone {
          max-width: calc(100% / 2);
        }
      }
      .total {
        display: none;
      }
      .price {
        display: none;
      }
    }
  }

  .checkboxes {
    .buttom {
      padding: 10px 20px;
      background-color: $gama;
      color: $beta;

      &hover {
        background-color: $beta;
        color: $gama;
      }
    }
  }

  .form-order-login {
    display: none;
  }
}

hr {
  color: $epsilon;
  background: $epsilon;
  border-color: $epsilon;
  border-top: none;
}

.row.checkboxes {

  .option {
    margin-left: 15px;
  }

  label {
    border: 1px solid $epsilon;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;

    &:hover {
      color: $red;
      background: $epsilon;
      border-radius: 5px;

      i {
        color: $red;
      }
    }
  }

  .check {
    display: block;
    position: relative;

    cursor: pointer;


    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .checkmark {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
  }

  .check {
    &:hover input ~ .checkmark {
      background-color: transparent;
    }

    input:checked ~ .checkmark {
      background-color: $epsilon;
      z-index: -1;
    }
  }

  .checkmark:after {
    content: " ";
    position: absolute;
    display: none;
    z-index: 0;
  }

  .check {
    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

    }
  }
}

.row.checkboxes.order-type {
  .col-4 {
    @include phone {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

  label {

    text-align: center;
    color: $alpha;
    font-weight: bold;
    text-transform: uppercase;
    background: none;
    border: none;

    i {
      color: $alpha;
      display: block;
      margin: 0 auto;
      clear: both;
      font-size: 48px;
    }

    &:hover {
      color: $red;
      background: $epsilon;
      border-radius: 5px;

      i {
        color: $red;
      }
    }
  }

  .check {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .checkmark {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
  }

  .check {
    &:hover input ~ .checkmark {
      background-color: transparent;
    }

    input:checked ~ .checkmark {
      background-color: $epsilon;
      z-index: -1;
    }
  }

  .checkmark:after {
    content: " ";
    position: absolute;
    display: none;
    z-index: 0;
  }

  .check {
    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

    }
  }

}


.order-form-data {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  .form-group {
    flex: 1 1 50%;
    max-width: 50%;
    @include phone {
      flex: 1 1 100%;
      max-width: 100%;
    }

    .col-4 {
      padding-left: 20px;
      @include phone {
        flex: 1 1 100%;
        max-width: 100%;
        padding-right: 0;
      }
    }
  }
}

@include desktop {
  #form_order {
    width: 100%;
    overflow-x: auto;

    .shopping-cart {
     
    }
  }
}

@include phone {
  #form_order .shopping-cart {
   
    max-width: 740px;
  }
}

@include phone {
  #form_order2 .form-group {
    flex-flow: row wrap;
    .col-2, .col {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
}
