div.member {
  div.member-home,
  div.member-edit,
  section {
    max-width: 100%;
    overflow: hidden;

    div.thumbnail {
      max-width: 200px;
      overflow: hidden;

      img {
        max-width: 200px;
        max-height: 180px;
      }

      .rsdir-no-image {
        height: 180px;
      }
    }
  }
}

.signup {
  .registration {
    padding: 0;
    margin: 0;
  }
}

.tabcontainer {
  display: grid;
}

.info {
  float: right;
}

abbr[title]:active::before {
  content: attr(title);
  color: #00f;
}

#cat_holder {
  display: none;
}

#member_cat_select {
  display: none;

  ul {
    margin: 10px 0;

    li {
      font-size: 16px;
      line-height: 22px;
      margin-left: -24px;
      margin-bottom: 10px;
    }
  }
}

#member_form {
  display: none;

  article {
    padding: 10px;
  }
}

section.signup {
  .registration form > .row {
    border-bottom: 1px solid $epsilon;
    margin: 0;

    .form-group {
      margin: 0;
      padding: 10px 0;
      @include lphone {
        flex: 1 1 100%;
        max-width: 100%;
      }

      .col-sm-5 {
        padding-left: 10px;
      }
    }

    &:nth-child(2n) {
      background: rgba($epsilon, 0.25);
    }
  }

  button {
    background: $white;
    border: 1px solid $red;
    color: $red;
    max-width: 200px;
    text-align: center;
    border-radius: 0;
    margin: 10px auto;

    &:hover {
      background: $red;
      color: $white;
    }
  }

  label {
    display: block;
    clear: both;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: none;
      border: 1px solid $alpha;
      overflow: hidden;

      &:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        position: absolute;
        display: none;
        height: 22px;
        width: 22px;
        line-height: 22px;
        text-align: center;
      }
    }

    &:hover input ~ .checkmark {
      background-color: $red;
    }

    input:checked ~ .checkmark {
      background-color: $red;
    }

    input:checked ~ .checkmark:after {
      display: block;
      color: $white
    }
  }

  .col-form-label {
    font-size: 14px;
  }
}
