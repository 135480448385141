nav#menu {
  display: none;
  padding: 0;

  ul {
    float: right;
    width: 100%;
    li {
      margin: 0 auto;
      color: $beta;
      font-size: 15px;
      line-height: 29px;
      text-transform: uppercase;
      &:hover{
        background-color: #da0000 ;
        color: #fff;
        a:hover {
          color: #fff;
        }
      }
      @include hd-desktop {
        margin: 0 5px;
        font-size: 14px;
        line-height: 16px;
      }
      a {
        color: $beta;
        &:hover{
          color: #fff;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
        .dropdown-menu {
          right: 0;
          left: auto;
          &::after {
            left: 190px;
          }
        }
      }
    }
  }
}

.lang-bar {
  padding-top: 36px;
  color: $alpha;
  font-size: 11px;
  min-width: 80px;
  margin-right: -16px;

  a {
    padding: .5rem 0;
  }
}

.dropdown {
  &:hover {
    .dropdown-content,
    .dropdown-menu {
      display: block;
    }
  }
  
  .dropdown-menu {
    margin: 0;
    background: $white;
    border-top: 2px solid #CA242A;
    padding: .7rem 0;
    width: 250px !important;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 30px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 20px 0 20px;
      border-color: $red transparent transparent transparent;
    }
    .dropdown-item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      align-items: center;
      margin: 0px !important;
      padding: 7px 10px;
      &:hover {
       ul {
         display: block;
       }
      }
      &.active {
        background-color: #f8f9fa;
      }
      
  
      ul {
        display: none;
        background: #fff;
        position: absolute;
        margin-top: 15px;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 250px;
        border: 1px solid rgba(0,0,0,0.15);
        right: 248px;
        top: 10px;
        li {
          max-width: 100%;
          line-height: 19px !important;
          margin: 0 !important;
          padding: 15px 10px;
          &:hover {
            background-color: #f8f9fa;
          }
        
          a {
            white-space: normal;
          }
        }
      }
      a {
        text-transform: initial;
        letter-spacing: initial;
      }
      &:active {
        background-color: #f8f9fa;
      }
    }
  }
}

#burger {
  a {
    cursor: pointer;
    padding: 12px 0;
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 9998 !important;
    @include tablet {
      top: 8px;
      left: 8px;
      right: none;
    }

    span {
      position: relative;
      display: block;
      width: 20px;
      height: 4px;
      background: $red;
      transition: all .2s ease-in-out;

      &:before, &:after {
        position: absolute;
        background: $red;
        content: '';
        width: 20px;
        height: 4px;
        transition: all .2s ease-in-out;
      }

      &:before {
        top: -8px;
      }

      &:after {
        top: 8px;
      }
    }

    &.active {
      span {
        background: transparent;

        &:before {
          transform: rotate(45deg) translate(5px, 6px);
        }

        &:after {
          transform: rotate(-45deg) translate(5px, -6px);
        }
      }
    }
  }
}

@media (min-width: 576px) {
  #burger {
    a {
    }
  }
}

@media (min-width: 768px) {
  .lang-bar {
    padding-top: 35px;
    font-size: 13px;
    margin-right: inherit;
  }
  #burger {
  }
  a {
  }
}

@media (min-width: 992px) {
  nav#menu {
    display: block;
    /*ul li:first-of-type {
        display: none;
    }*/
  }
  .lang-bar {
    padding-top: 56px;
    min-width: 89px;
  }
  #burger {
    a {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  nav#menu {
    ul li:first-of-type {
      display: block;
    }
  }
}


ul.mm-listview {
  li.cart {
    span {
      font-size: 12px;
    }
  }
  li.search {
    padding: 10px 20px;
    form {
      display: flex;
      justify-content: flex-start;
      flex-flow: row nowrap;
      max-width: 100%;
      input {
        flex: 1 1 100%;
        max-width: calc(100% - 40px);
        line-height: 40px;
        height: 40px;
        border: none;
        background: $white;
        padding: 0 20px;
        box-sizing: border-box;
      }
      button {
        flex: 1 1 40px;
        max-width: 40px;
        height: 40px;
        background: $red;
        color: $white;
        border: none;
        display: flex;
        justify-content: center;
        flex-flow: row nowrap;
        align-items: center;
      }
    }
  }
}
