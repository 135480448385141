input::placeholder {
  text-transform: uppercase;
}

button {
  background: $epsilon;
  color: $beta;
  padding: 10px 20px;
  border: 0;
  text-transform: uppercase;
  &.right {
    float: right;
  }
}

.btn-alpha {
  background: $alpha;
  color: $white;
  border-radius: 0;
  text-transform: uppercase;
}

.btn-beta {
  background: $beta;
  color: $white;
  border-radius: 0;
  text-transform: uppercase;
}

/*.btn-primary{
    background: #efe09d;
    color: $beta;
    border-color: #efe09d;
    &:hover {
        background: $beta;
        color: #efe09d;
        border-color: $beta;
    }
}*/

input[type=number], input.integer, input.percent {
  text-align: right;
  letter-spacing: 4px;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*.form-control.integer {
	width: 50%;
}*/

.custom-file-input {
  /*visibility: hidden;*/
  display: none;
}

.custom-file-label {
  width: 100%;
  margin: 5px 0;
  &.btn {
    background: #70c6f0;
    color: white;
    padding: 10px 20px;
    border: 0;
  }
  &.btn-small {
    padding: 0 20px;
  }

}

small.form-text {
  text-align: center
}

.img-thumbnail-holder {
  width: 200px;
  height: 200px;
  img {
    max-width: 200px;
    max-height: 200px;
  }
}

.section-title {
  font-weight: bold;
}

label.form-control.file-upload {
  position: relative;
  &:after {
    position: absolute;
    right: 10px;
    top: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    content: '\f093';
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
  }
  input {
    opacity: 0;
    visibility: hidden;
  }
}

#contact {
  textarea.form-control {
    min-height: 150px;
    text-transform: unset;
  }
  input.form-control {
    text-transform: unset;
    &::placeholder {
      text-transform: unset;
    }
  }
  button[type="submit"] {
  margin-bottom: 20px;
  background: $red;
  color: $white;
  border: 1px solid $red;
  &:hover {
    background: $white;
    color: $red;
  }
}
}
