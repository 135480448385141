
// @mixin title_mask {
//     .link-detail{
//         float: right;
//     }
// }

.quote {
    font-style: italic;
    font-size: 18px;
    line-height: 28px;
}
.cite {
    font-size: smaller;
}

.colapse{
    /* css driven collapse */
    [id^="toggleBlock"],                        /* HIDE CHECKBOX */
    [id^="toggleBlock"] ~ .block,               /* HIDE LIST */
    [id^="toggleBlock"] + label  span + span,   /* HIDE "Collapse" */
    [id^="toggleBlock"]:checked + label span {   /* HIDE "Expand" (IF CHECKED) */
      display:none;
    }
    [id^="toggleBlock"]:checked + label span + span{
      display:inline-block;                 /* SHOW "Collapse" (IF CHECKED) */
    }
    [id^="toggleBlock"]:checked ~ .block{
      display:block;                        /* SHOW LIST (IF CHECKED) */
    }
    .toggle-block {
        label {
            background: #eee;
            width: 100%;
            cursor: pointer;
            span {
                float: right;
            }
        }
        .block {
            padding: 20px 20px 30px 20px;
        }
    }	
}
/*
.row.vdivide [class*='col-']:not(:last-child):after {
}*/

#box_latest_news {
    padding: 20px;
    article {
        padding: 10px;
    }    
    // h3 {
    //     font-weight: normal;
    //     font-size: 20px;
    // }
    .date {
        color: $gama;
        font-size: 12px;            
    }
    // .abstract {display:inherit}
    // &:first-of-type {
    //     h3 {
    //         font-size: 22px;
    //     }
    //     .date {display: none}
    //     .abstract {
    //         display:contents;
    //         color: $gama;
    //         font-size: 12px;
    //     }
    // }    
}

#overlay, .overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: default; /* Add a pointer on hover */
}

@media (min-width: 576px) {
    // .ibox-txt {
    //     color: $white;
    // }
}

@media (min-width: 768px) {
    #box_latest_news {
        padding: 10px;
        article { }
        h3 {
            font-weight: normal;
            font-size: 20px;
        }
        .date {
            color: $gama;
            font-size: 12px;            
        }
        .abstract {display:none}

        // &:first-of-type {
        //     height: 350px;
        //     float: left;
        //     padding-left: 0px;
        //     h3 {
        //         font-size: 22px;
        //     }
        //     .date {display: none}
        //     .abstract {
        //         display:contents;
        //         color: $gama;
        //         font-size: 12px;
        //     }
        // }
    }
}


@media (min-width: 992px) {
    // div.img-mask {
    //     height:343px;
    //     h1 {
    //         font-size: 42px;
    //         line-height: 48px;
    //     }
    // }
    // .ibox {
    //     width: 354px;
    //     height: 354px;
    // }
    // .ibox-txt {
    //     color: $white;
    //     column-count: 2;
    //     column-gap: 20px;
    // }
}

@media (max-width: 992px) {
}

@media (min-width: 1200px) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
}/**/