header {
  /*
  max-width: 1140px;
  margin: 0 auto;
  */
  color: $alpha;
  font-size: 20px;
  background-color: #fff;
  border-bottom: 2px solid $red;

  a {
    //color: #CA242A;
  }

  .container {
    background-color: $white;
  }

  .top-bar {
    background-color: $white;
    //border-bottom: 1px solid $alpha;
  }

  .top {
    padding-top: 10px;
    .logo-img {
      max-width: 100%;
    }
    .box-socials {
      display: inline-block;
      margin: auto;
      @include lphone {
        margin-top: 10px;
      }
      .social {
        padding-left: 0;
        text-align: end;
        font-size: 30px;
        line-height: 50px;
        margin-bottom: 0;
        @include lphone {
          text-align: center;
        }
        li {
          display: inline-block;
          text-align: center;
          padding-right: 10px;
          &:last-child {
            padding-right: 0;
          }
          @include tablet {
            padding-right: 7px;
          }
          @include desktop {
            padding-right: 10px;
          }
          a i {
            color: #b6b4b5; 
            &:hover {
              color: #CA242A;
            }
          }
        }
        li.lng-icon{
          img{
            width: 20px;
          }
        }
      }
    }
    .search-top {
      display: block;
      margin: auto;
      padding: 0 50px;
      @include lphone {
        padding: 0;
      }
      @include tablet {
        padding: 0;
      }
      input {
        display: inline-block;
        width: 100%;
        padding: 5px 10px;
        position: relative;
        margin-right: 1%;
        border: 1px solid #c5c6c8;
        background: transparent;
        z-index: 998;
        @include tablet {
          width: 100%;
        }
    
        &:focus {
          outline: none;
        }
        &::placeholder {
          text-transform: unset;
          font-size: 15px;
        }
      }
      &:after {
        position: absolute;
        content: "\f002";
        font-family: "Font Awesome 5 Free";
        top: 0px;
        right: 8%;
        width: 36px;
        height: 36px;
        line-height: 36px;
        display: block;
        font-weight: bold;
        font-size: 24px;
        color: #CA242A;
        @include lphone {
          right: 0;
        }
      }
    }
    .cart {
      #box_order {
        .box_order {
          padding-left: 0;
          span.number {
            position: absolute;
            font-size: 11px;
            line-height: 20px;
            font-weight: 800;
            color: #fff;
            right: 2px;
            top: 0;
            border: #CA242A;
            border-radius: 19px;
            width: 19px;
            background-color:#CA242A;
            height: 19px;
            @include lphone {
              right: auto;
            }
          }
        }
      }
    }
    text-transform: uppercase;
    //padding-top: 10px;
    //padding: 10px;
    //border-bottom: 1px solid $alpha;
    font-size: 14px;
    background: #fff;
    @include phone {
      max-width: 320px;
      margin: 0 auto;
    }
    @include lphone {
      a {
        img {
          width: auto;
        }
      }
    }
    @include tablet {
      a {
        display: block;
        img {
          margin: 0 auto;
          display: block;
        }
      }
    }
    .logo {
      img {
        max-width: 100%;
      }
    }
    .navitop {
      margin: 0;
    }

    .phone-number {
      ul {
        list-style: none;
        float: left;
        margin: 0;
      }

      li {
        float: left;
        padding: 0 10px;
      }
    }

    .top-social {
      ul {
        list-style: none;
        float: left;
        margin: 0;
      }

      li {
        float: left;
        padding: 0 10px;
      }
    }

    .lang-bar {
      padding-top: 0;
    }
  }

  // header menu //
  .top-info {
    right: 0px;
    //text-transform: uppercase;
    //padding-top: 10px;
    //padding: 10px;
    font-size: 14px;
    // background: #fff;

    ul {
      list-style: none;
      float: left;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      flex-flow: row wrap;
      align-items: center;
      height: 93px;
    }

    li {
      padding: 0 10px;
      color: $red;
      line-height: 40px;
      &.top_login, &.top_register, &.search, &.cart {
        @include tablet {
          display: none;
        }
      }

      a {
        color: $red;
      }

      &.search {
        position: relative;
        margin: 0 20px;
        width: 200px;

        form {
          display: flex;
          justify-content: flex-end;
          flex-flow: row nowrap;
        }


        input {
          width: 0;
          line-height: 38px;
          border: 1px solid $red;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          transition: all 0.5s;
          background: none;
        }

        button {
          color: $red;
          visibility: visible;
          opacity: 1;
          width: 40px;
          height: 40px;
          background: none;
          padding: 0;
          text-align: center;
        }


        &:hover, &:focus {
          input {
            opacity: 1;
            visibility: visible;
            width: 200px;
          }
        }
      }
    }

    .text-right {
      //width: 100%;
      float: right;
      margin: 0;
      position: relative;
      list-style: none;
      padding: 0;
      //top: 25%;
      @include tablet {
        display: none;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 575.98px) {
  header {
    a {
      img {
        //max-width: 283px;
        left: 0;
        position: relative;

      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 767.98px) {
}

// Large devices (desktops, 992px and up)
@media (max-width: 991.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1199.98px) {
}
