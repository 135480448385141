.news {
  * {
    max-width: 100% !important;
  }

  h1 {
    font-weight: normal;
    margin: 40px 0 30px !important;
    color: #5e5e5e;
  }

  //img {}
}

.gallery {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap !important;

  .thumbnail {
 
    float: none;
    margin-bottom: 20px;
    flex: 1 1 25%;
    max-width: 23.5% !important;
    margin: 0 2% 20px 0;
    overflow: hidden;
    max-height: 174px;
    
    
    
    // img{
    //   width: 100%;
    //   height: 100%;
    // }
   

    &:nth-child(4n) {
      margin-right: 0;
    }

    @include tablet {
      flex: 1 1 50%;
      max-width: 48% !important;
    }
    @include phone {
     
      flex: 1 1 100%;
      max-width: 100% !important;
    }
  }
}

.cat-vesti, .cat-blog, .cat-novosti,.cat-novosti-za-zaposlene,.cat-news {
  padding: 50px 0;
  background: none !important;

  .row {
    margin: 0;
    padding: 0 15px;
  }

  h1 {
    color: $alpha;
    line-height: 40px;
    position: relative;
    text-transform: uppercase;
    flex: 1 1 30%;
    max-width: 30%;
    display: flex !important;
    font-size: 24px;
    font-weight: bold;
    height: 40px;
    @include phone {
      flex: 1 1 100%;
      max-width: 100%;
    }

    span {
      color: $red;
      border-bottom: 1px solid $red;
      margin-left: 15px;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 1px;
        position: absolute;
        left: -16px;
        top: 5px;
        bottom: 5px;
        background: $alpha;
        margin-left: 10px;
      }
    }
  }

  .search {
    padding: 0;
    position: relative;
    flex: 1 1 65%;
    max-width: 65%;
    margin-left: 5%;
    margin-bottom: 50px;
    @include phone {
      flex: 1 1 100%;
      max-width: 100%;
      margin: 20px 0;
    }

    &:after {
      position: absolute;
      content: "\f002";
      font-family: "Font Awesome 5 Free";
      top: 0;
      right: 10px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      display: block;
      font-weight: bold;
      font-size: 24px;
      color: $red;
    }

    input {
      display: block;
      width: 100%;
      padding: 0 10px;
      border: 1px solid $red;
      position: relative;
      height: 40px;
      line-height: 40px;

      &:focus {
        outline: none;
      }
      &::placeholder {
        text-transform: unset;
        font-size: 15px;
      }
    }
  }

  article.item {

    .row {
      margin: 0;
      padding: 0;
      align-items: flex-start;
    }

    .blog-post {
      // max-width: 32%;
      // margin-right: 2%;
      // margin-bottom: 20px;
      padding: 0;
      min-height: 445px;
      height: 100%;
      transition: all .3s ease-out;
      &:hover{
        transform: translateY(-4px);
      }
      @include tablet {
        // max-width: 49%;
        // &:nth-child(2n) {
        //   margin-right: 0;
        // }
        // &:nth-child(3n) {
        //   margin-right: 2% !important;
        // }
      }
      @include lphone {
        max-width: 100%;
      }

      figure {
        display: block;
        margin-bottom: 20px;

        .thumbnail {
          display: block;
          width: 100%;
          float: none;

          img {
            width: 100%;
          }
        }
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .blog-post-wrapper{
    margin-bottom: 24px;
    height: 100%;
  }

  .blog-post {
    background: $white;
    -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    // margin-bottom: 20px;

    h2 {
      display: block;
      width: 100%;
      padding: 10px 15px;
      border-bottom-color: $red;

      span {
        font-size: 14px;
        color: $alpha;

      }

      a {
        font-size: 20px;
        font-weight: bold;
        display: block;
        color: #5e5e5e;
        font-weight: bold;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        //white-space: nowrap;
        transition: all .2s;
        &:hover{
          color: #da0000;
        }
      }
    }

    p {
      padding: 0 15px;
      font-size: 16px;
    }
  }
}

