//main{}

.container.bg-white {
    background-color: $white;
}

#website-order .inner {
    background: #fff;
}

.inner {
    min-height: 824px;
}

div.intro{
    color: $alpha;
    background-color: $gray;
    margin-bottom: 92px;
    h1 {
        color: $beta;
        text-align: center;
    }
}
// div.index-info{
//     background-color: $white;
// }

/*div.inner > .row > div[class^="col-"] {
    margin: 0;    
    padding: 0;
}*/

.small-txt{
    font-size: 12px;
}

.thumbnail {
    float: left;
    margin-right: 30px;
}

.page {
    table {
        tr {
            background: #eee;            
            td:nth-child(2n) {
                background: #ddd;                
            }
        }
        td > * {
            margin: 10px;
        }
        thead {
            tr {
                background: #ddd;
                td:nth-child(2n) {
                    background: #ccc;
                }
            }
        }
    }

}

/* page contact */
.page-contact { 
    .contact-h1{
        color: #000;
        font-size: 25px;
    }
    margin: 0;
    .form-box, .registration {
        float: left;
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 20px;
        .title {
            display: flex;
        }
        .left {
            float: left;
            margin-bottom: 25px;
            p {
                text-align: initial;
                font-size: 17px;
                @include tablet-only {
                    font-size: 16px;
                }
            }
            &.two {
                padding-left: 0;
                padding-right: 0;
                @include lphone {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
}

.ad{
    .item-thumbnail{
        margin-right: 20px;
        margin-bottom: 5px;
    }
    .ad-vote-holder{
        text-align: center;
        padding: 20px;
    }    
}

#popup-terms {
    z-index: 99999;
    min-height: 20px;
    padding: 10px 20px;
    background: rgba(0,0,0,0.6);
    overflow: hidden;
    position: fixed;
    color: $white;
    bottom: 0px;
    right: 0px;
    display: block;
    left: 0;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    a {
        color: aquamarine;
        &#cookies-policy {
            display: inline-block;
            color: $black;
            font-size: 1.1em;
            background: #ffba55;
            text-decoration: none;
            cursor: pointer;
            padding: 2px 20px;
            float: right;
            border-radius: 20px;
        }
    }
}

// responsive

@media (min-width: 576px) {
    // .slogan{
    // }
    // .inner {
    // }    
    // .news {
    //     img {
    //         max-width: 720px;
    //     }        
    // }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    // .teaser {
    //     figure {}
    //     figcaption {
    //     }
    // }
    // .ibox-title{
    //     background: none !important;
    //     .slogan {
    //         display: none;
    //     }
    //     h1 {
    //         width: 50%;
    //     }
    // }
}

@media (max-width: 992px) {
    // .ibox-title {
    //     .slogan {
    //         font-size: 38px;
    //     }
    //     text-align: center;
    //     h1 {
    //         text-align: right;
    //         float: right;
    //         margin-right: 30px;
    //     }
    // }
    // .ibox-txt {
    //     color: $alpha;
    //     p:empty {
    //         background: #ff0000;
    //     }
    // }
    // .img-mask2b p {
    //     color: $zeta;
    // }    
}

@media (min-width: 1200px) {
    // .teaser figcaption {
    //     display: block;
    //     .slogan {
    //         span {
    //         }
    //     }
    // }    
}
